export const limitation = {
    nodes: {
        plainMessageTextNode: {
            maxCharacterLength: 4096,
        },
        questionNode: {
            maxCharacterLength: 4096,
        },
        buttonNode: {
            bodyText: 1024,
            // "bodyText": 20,
            maxButtons: 10,
        },
        listNode: {
            bodyText: 1024,
            maxListItems: 10,
        },

        optionNode: {
            header: 1024,
            maxListItems: 10,
        },
        mediaNode: {
            caption: 1024,
            fileDisplayNameForDocument: 52,
        },
        mediaInputNode: {
            textLength: 1024,
        },
        webhookNode: {},
        dynamicListNode: {
            bodyText: 1024,
            maxListItems: 10,
        },
        dynamicButtonNode: {
            bodyText: 1024,
        },
        cacheNode: {
            cacheKey: 200,
            ttl: 18000
        }
    },
    channels: {
        whatsapp: {
            channelId: "whatsapp",
            nodes: {
                plainMessageTextNode: {
                    maxCharacterLength: 4096,
                },
                buttonNode: {
                    headerText: 60,
                    footerText: 60,
                    bodyText: 1024,
                    // "bodyText": 20,
                    maxButtons: 3,
                },
                listNode: {
                    openButtonText: 20,
                    bodyText: 1024,
                    maxListItems: 10,
                    listTitle: 10,
                },
                optionNode: {
                    header: 1024,
                    maxListItems: 10,
                    listTitle: 10,
                },
                mediaNode: {
                    caption: 1024,
                },
                dynamicListNode: {
                    openButtonText: 20,
                    bodyText: 1024,
                    maxListItems: 10,
                    listTitle: 10,
                },
                whatsAppFlowsNode: {
                    headerText: 60,
                    footerText: 60,
                    bodyText: 1024,
                    openButtonText: 60,
                },

                whatsAppTemplateNode: {
                    headerText: 60,
                    footerText: 60,
                    bodyText: 1024,
                    openButtonText: 60,
                },
                carouselNode: {
                    headerText: 60,
                    footerText: 60,
                    bodyText: 1024,
                    openButtonText: 60,
                },
                whatsAppPaymentNode: {
                    headerText: 2000,
                    footerText: 60,
                    bodyText: 1024,
                    paymentConfiguration: 60,
                    itemName: 60,
                    description: 60,
                    TimestampDescription: 120,
                    responseText: 900,
                    retailerId: 60,
                },
                whatsAppCatalogNode: {
                    headerText: 60,
                    footerText: 60,
                    bodyText: 1024,
                },
                whatsAppCTANode: {
                    headerText: 60,
                    footerText: 60,
                    bodyText: 1024,
                    text: 60,
                },
            },
        },
        tubulu: {
            channelId: "tubulu",
            nodes: {
                // ----------------- NO limit for text length
                // plainMessageTextNode: {
                //   maxCharacterLength: 10240000,
                // },
                // --------------- No limit for quick button actions
                // buttonNode: {
                //   headerText: 20,
                //   footerText: 60,
                //   bodyText: 1024,
                //   maxButtons: 3,
                // },
                // ----------------- No limit
                // mediaNode: {
                //   caption: 1024,
                // },
            },
        },
        telegram: {
            channelId: "telegram",
            nodes: {
                plainMessageTextNode: {
                    maxCharacterLength: 1024,
                },
                buttonNode: {
                    bodyText: 1024,
                    maxButtons: 1000,
                },
            },
        },
        messenger: {
            channelId: "messenger",
            nodes: {
                plainMessageTextNode: {
                    maxCharacterLength: 1024,
                },
                buttonNode: {
                    bodyText: 1024,
                    maxButtons: 100,
                },
            },
        },
        instagram: {
            channelId: "instagram_messenger",
            nodes: {
                plainMessageTextNode: {
                    maxCharacterLength: 500,
                },
                media: {
                    audio: {
                        supportedFormatType: ["aac", "m4a", "wav", "mp4"],
                    },
                    image: {
                        supportedFormatType: ["png", "jpeg"],
                    },
                    video: {
                        supportedFormatType: ["mp4", "avi", "mov", "webm"],
                    },
                },
                buttonNode: {
                    maxButton: 3,
                },
            },
        },
        sms: {
            channelId: "sms",
            nodes: {
                plainMessageTextNode: {
                    maxCharacterLength: 2000,
                },
            },
        },
        rcs: {
            channelId: "rcs",
            nodes: {
                plainMessageTextNode: {
                    // this limit use in both Text Message card and Question Card 
                    maxCharacterLength: 3072,
                },
                buttonNode: {
                    bodyText: 3072,
                    maxButtons: 11,
                },
                rcsStandAloneNode: {
                    maxButtons: 4,
                    // title: 30,
                    title: 200,
                    text: 3072,
                    description: 2000,
                },
                rcsCarouselNode: {
                    title: 200,
                    bodyText: 2000,
                    buttonName: 25,
                }
            },
        },
    },
    description: {
        maxCharacterLength: 100,
    },
};
