import { generate } from "short-uuid";
import { processWorkflowService } from "../../pages/pages/Bots/workflow-builder/service/processWorkflow.service";
import { workflowService } from "../../pages/pages/Bots/workflow-builder/service/workflow.service";
import { generateNodeValidation } from "../../pages/pages/Bots/workflow-builder/utils/generateNodeValidation";
import {
    checkIsChannelValidForChannelSwitchNode,
    getChannelIdByChannelName,
} from "../../utils/channelsUtils";
import { NODE_TYPES, workflowTypes } from "../../utils/Constants/WorkflowConstants";
import WorkflowErrors from "../../utils/workflowErrors";
import { reports_key } from "./genericValidation";
import { Queue } from "./Queue";
import { suggestedVariableValidation } from "./suggestedVariableValidation";
import {
    traversalForDeletingLoopBack,
    traverseToAllChildNodesForValidations,
    traverseToAllNodesForAddChannelIdAndParentId,
    traverseToAllNodesForDeleteChannelIdAndParentId,
    validateAllNodes,
} from "./traversal";
import { nodeValidationsLogic } from "./validations";
import { SubWorkflowValidation } from "./subWorkflowLogic";
import { TraversalClass } from "./newTraversalClass";
import { createNewNodeUsingExistingNode } from "./helperFunction";

export function generateUniqueIdForCard() {
    return generate();
}

const nodeErrorStyle = {
    // boxShadow: "rgb(244 109 109) 0px 1px 15px 1px",
    // border: "2px solid rgb(244 109 109)",
};

const removeTemporaryDataFromNodesAndEdges = (nodes, edges) => {
    try {
        const updatedNodes = nodes.map(node => ({ ...node, positionAbsolute: undefined, selected: false, isActive: false }));
        const updatedEdges = edges.map(edge => ({ ...edge, positionAbsolute: undefined, selected: false, isActive: false }));
        return { nodes: updatedNodes, edges: updatedEdges };
    } catch (error) {
        return { nodes, edges };
    }
};

export const saveWorkflowData = async (state, saveType) => {
    let snackbar = { open: true, severity: "", message: "" };
    try {
        const botId = state.botId;
        const workflowId = state.workflowId;
        const { usedSubflowIdArray, errorCount } = findUsedSubflowsInNodesArray({
            nodes: state.nodes,
            errorCount: state.logCount.errorCount,
        });
        const { nodes, edges } = removeTemporaryDataFromNodesAndEdges(state.nodes, state.edges);
        const response = await workflowService.updateWorkflowDataAsync({
            botId,
            workflowId,
            nodes: nodes,
            edges: edges,
            variables: state.variables,
            variableSuggestionList: state.variableSuggestionList,
            errorCount,
            usedSubflowIdArray,
            defaultLocale: state.defaultLocale,
            localeSupportedByBot: state.localeSupportedByBot,
            workflowSaveType: saveType,
            selectedLocale: state.locales,
        });
        if (response) {
            if (
                response.data &&
                (response.data.status === "SAVED" ||
                    response.data.message === "updated")
            ) {
                snackbar.severity = "success";
                snackbar.message = response.data.message;
            } else if (response.response) {
                if (response.response.data && response.response.data.message) {
                    snackbar.severity = "error";
                    snackbar.message = response.response.data.message;
                } else {
                    snackbar.severity = "error";
                    snackbar.message = response.response.statusText;
                }
            } else {
                snackbar.severity = "error";
                snackbar.message = response.message;
            }
        } else {
            snackbar.severity = "error";
            snackbar.message = `Server is not reachable.`;
        }
    } catch (error) {
        snackbar.severity = "error";
        snackbar.message = `Server Exception ${error}`;
    } finally {
        return snackbar;
    }
};

export const deployBot = async (state, action) => {
    try {
        const botId = state.botId;
        const workflowId = state.workflowId;

        const response = await workflowService.deployBotAsync({
            botId,
            workflowId,
            nodes: state.nodes,
            edges: state.edges,
            variables: state.variables,
            variableSuggestionList: state.variableSuggestionList,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createWorkflowServices = async ({ name, botId }) => {
    try {
        const response = await workflowService.createWorkflow({ name, botId });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteWorkflowService = async (botId, workflowId) => {
    try {
        const response = await workflowService.deleteWorkflow(botId, workflowId);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const findUsedSubflowsInNodesArray = ({ nodes, errorCount }) => {
    let newErrorCount = errorCount;
    let usedSubflowIdArray = [];
    const subflowNode = nodes.filter((node) => node.type === "subFlowNode");
    if (subflowNode.length !== 0) {
        for (let i = 0; i < subflowNode.length; i++) {
            const subflowContent = subflowNode[i].data.contents;
            if (
                subflowContent.subFlow !== undefined &&
                subflowContent.subFlow.id !== undefined &&
                subflowContent.subFlow.id !== ""
            ) {
                usedSubflowIdArray.push(subflowContent.subFlow.id);
                if (subflowContent.subFlow.errorsInSubflow !== 0) {
                    newErrorCount -= subflowContent.subFlow.errorsInSubflow > 0 ? 1 : 0;
                }
            }
        }
    }

    // workflow list 
    // add subworkflow id into usedSubflowIdArray in default workflow.
    // so user can't delete subworkflow if used in default workflow.
    // also check the error count and replace.
    const startNode = nodes.find((node) => node.type === "startNode");
    if (startNode) {
        const startNodeData = startNode.data.subWorkFlowKeyword;
        if (startNodeData && startNodeData.length !== 0) {
            for (let index = 0; index < startNodeData.length; index++) {
                const element = startNodeData[index];
                if (
                    element.workflowId !== undefined &&
                    element.workflowId !== ""
                ) {
                    usedSubflowIdArray.push(element.workflowId);
                    if (element.errorCount !== 0) {
                        newErrorCount -= element.errorCount > 0 ? 1 : 0;
                    }
                }
            }
        }
    }

    return {
        usedSubflowIdArray: [...new Set([...usedSubflowIdArray])],
        errorCount: newErrorCount,
    };
};

export const pullUploadedMediaListFromServer = async (state, action) => {
    try {
        const response = await workflowService.getMediaListFromServerAsync();
        return response;
    } catch (error) {
        throw error;
    }
};

export const hidePropertyMenuForSelectedNodeType = (nodeType) => {
    return ["channelSwitchNode"].includes(nodeType);
};

export const inputCardsArray = [
    "questionNode",
    "listNode",
    "buttonNode",
    "mediaInputNode",
    "locationInputNode",
    "dynamicListNode",
    "dynamicButtonNode",
    "documentCognitionCard",
    "httpTriggerNode",
    "whatsAppFlowsNode",
    "whatsAppTemplateNode",
    "whatsAppPaymentNode",
    // "liveAgentNode",
    // "subflowNode"
    "whatsAppCatalogNode",
    "rcsStandAloneNode",
    "WhatsAppCTANode",
    "carouselNode",
];

export const validCardTypeAllowedInLoopBackCycle = [NODE_TYPES.ITERATION_GUARD_NODE]

/**
 * This method check if a given node is input card or not
 * @param {*} nodeType
 * @returns boolean
 */
export const checkIfCardInInputCard = (nodeType) => {
    return inputCardsArray.includes(nodeType);
};

export const renderableCards = [
    "plainMessageTextNode",
    "questionNode",
    "optionNode",
    "listNode",
    "buttonNode",
    "dynamicListNode",
    "dynamicButtonNode",
    "locationInputNode",
    "mediaNode",
    "mediaInputNode",
    "carouselNode",
    "liveAgentNode",
    "documentCognitionCard",
    "httpTriggerNode",
    "whatsAppFlowsNode",
    "whatsAppTemplateNode",
    "whatsAppPaymentNode",
    "whatsAppCatalogNode",
    "rcsStandAloneNode",
    "whatsAppCTANode"
];

export const isNodeToPreview = (nodeType) => {
    return [
        "plainMessageTextNode",
        "questionNode",
        "buttonNode",
        "mediaNode",
        "mediaInputNode",
        "locationInputNode",
        "locationOutputNode",
        "listNode",
        "optionNode",
        // "carouselNode",
        "rcsStandAloneNode",
    ].includes(nodeType);
};

export const isFullScreenPropertyMenuCard = (nodeType) => {
    return ["startNode", "jsonMapperCard", 'webhookNode', 'messagingServiceNode', 'whatsAppTemplateNode'].includes(nodeType);
};

const supprotedImageTypeList = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/avif",
    "image/gif",
    "image/webp",
    "image/bmp",
];

const supprotedVideoTypeList = ["video/mp4", "video/3gpp"];

const supprotedDocumentTypeList = [
    "application/pdf",
    "octet-stream",
    // "application/zip",
    // "application/pkcs8",
    // "text/plain",
    // "text/csv",
    // "text/html",
    // "text/css",
    // "application/msword",
    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // "application/gzip",
    // "text/javascript",
    // "application/json",
    // "application/vnd.ms-powerpoint",
];

export const supportedFileTypesForChatPdf = [
    "text/plain",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
];

export const supportedFileTypesForLocalization = [
    "application/csv",
    "text/csv",
];

const supprotedAudioTypeList = [
    "audio/aac",
    "audio/mp4",
    "audio/amr",
    "audio/mpeg",
    "audio/ogg",
    "audio/opus",
];

export const getSupprotedTypesListBasedOnMediaType = (mediaType) => {
    if (typeof mediaType !== "string") {
        console.log(
            "mediaType must be of type 'string': getSupprotedTypesListBasedOnMediaType()"
        );
    }
    switch (mediaType.toLowerCase()) {
        case "images":
        case "image":
            return supprotedImageTypeList;

        case "videos":
        case "video":
            return supprotedVideoTypeList;

        case "audios":
        case "audio":
            return supprotedAudioTypeList;

        case "documents":
        case "document":
            return supprotedDocumentTypeList;

        default:
            return [];
    }
};

export const generateId = () => `${generateUniqueIdForCard()}`;

export const addNewNode = (state, action) => {
    const newNodesArray = [
        ...state.nodes.map((node) =>
            node.selected === true ? { ...node, selected: false } : node
        ),
        action.newNode,
    ];
    // find node with no edges and give warning
    const { nodes: updatedNodes } = checkUnConnectedNodes({
        nodes: newNodesArray,
        edges: state.edges,
    });

    const newState = {
        ...state,
        nodes: updatedNodes,
        currentlySelectedNode: null,
        currentlySelectedEdge: null,
        logCount: {
            errorCount: calculateErrorCount(updatedNodes),
            warningCount: calculateWarningCount(updatedNodes),
        },
    };
    return newState;
};

export const setCurrentlySelecteNode = (state, action) => {
    if (action.payload.node !== null) {
        const isNodeExit = state.nodes.find(
            (node) => node.id === action.payload.node.id
        );
        let newState = null;
        if (isNodeExit !== undefined) {
            newState = {
                ...state,
                currentlySelectedNode: action.payload.node,
                currentlySelectedEdge: null,
            };
        } else {
            newState = {
                ...state,
                currentlySelectedNode: null,
                latestSelectedNodeOrEdge: null,
                currentlySelectedEdge: null,
            };
        }
        return newState;
    } else {
        return {
            ...state,
            currentlySelectedNode: null,
            latestSelectedNodeOrEdge: null,
            currentlySelectedEdge: null,
        };
    }
};

export const setLatestSelectedNodeOrEdgeForDeletion = (state, action) => {
    if (action.payload.nodeId) {
        return {
            ...state,
            latestSelectedNodeOrEdge: { id: action.payload.nodeId, isNode: true },
        };
    }
    if (action.payload.edgeId) {
        return {
            ...state,
            latestSelectedNodeOrEdge: { id: action.payload.edgeId, isNode: false },
        };
    }

    return { ...state, latestSelectedNodeOrEdge: null };
};

// This will trigger traversal from the node which is deleted
export const deleteNode = (state, action) => {
    const nodeToDelete = state.nodes.find((node) => node.id === action.nodeId);
    if (nodeToDelete) {
        let newEdges = state.edges.map((edge) => {
            return {
                ...edge,
            };
        });
        let newNodes = state.nodes.map((node) => {
            return {
                ...node,
                id: node.id,
                data: {
                    ...node.data,
                    belongsToChannel: node.data.belongsToChannel.map(
                        (belongsToChannel) => ({
                            ...belongsToChannel,
                            channelId: belongsToChannel.channelId,
                            refCount: belongsToChannel.refCount,
                        })
                    ),
                    parentNodesIdArray: node.data.parentNodesIdArray.map(
                        (parentNodesId) => ({
                            ...parentNodesId,
                            refCount: parentNodesId.refCount,
                        })
                    ),
                    // reports:{
                    // 	errors:{
                    // 		...
                    // 	}
                    // }
                    // error: {
                    // 	...node.data?.error,
                    // 	flag: node.data?.error?.flag,
                    // 	message: node.data?.error?.message,
                    // },
                },
                styles: { ...node.data.style },
            };
        });

        // Handle all incoming edges of deleted node
        // This will disconnect all the incoming edges and mark their respective state as false
        const allInComingEdgesOfDeletedNode = newEdges.filter(
            (edge) => edge.target === action.nodeId
        );
        for (let index = 0; index < allInComingEdgesOfDeletedNode.length; index++) {
            const edge = allInComingEdgesOfDeletedNode[index];
            let sourceNodeOfIndividualEdge = findNode(newNodes, edge.source);
            // let errorObject = {
            // 	flag: false,
            // 	messages: [],
            // };
            let reports = sourceNodeOfIndividualEdge.data.reports ?? {
                errors: { messages: [] },
                warnings: { messages: [] },
            };
            if (
                edge.sourceHandle !== null &&
                edge.sourceHandle.includes("exception")
            ) {
                if (checkIfCardInInputCard(sourceNodeOfIndividualEdge.type) === true) {
                    // only validate if the validate properti is set to true
                    if (
                        sourceNodeOfIndividualEdge.data.validation &&
                        sourceNodeOfIndividualEdge.data.validation.validate &&
                        sourceNodeOfIndividualEdge.data.validation.validate === true
                    ) {
                        sourceNodeOfIndividualEdge.data.validation = {
                            ...sourceNodeOfIndividualEdge.data.validation,
                            isOnErrorHandleConnected: false,
                        };
                    }
                }
            }

            // ====================== HANDLE INPUT TIMEOUT ======================
            if (
                edge.sourceHandle !== null &&
                edge.sourceHandle.includes("input_timeout")
            ) {
                if (checkIfCardInInputCard(sourceNodeOfIndividualEdge.type)) {
                    if (
                        sourceNodeOfIndividualEdge.data.inputTimeout &&
                        sourceNodeOfIndividualEdge.data.inputTimeout.enabled &&
                        sourceNodeOfIndividualEdge.data.inputTimeout.enabled === true
                    ) {
                        sourceNodeOfIndividualEdge.data.inputTimeout = {
                            ...sourceNodeOfIndividualEdge.data.inputTimeout,
                            isTimeoutHandleConnected: false,
                        };
                    }
                }
            }
            // ====================== HANDLE INPUT TIMEOUT ======================

            // if start node is disconnected
            if (sourceNodeOfIndividualEdge.type === "startNode") {
                sourceNodeOfIndividualEdge.data.contents = {
                    ...sourceNodeOfIndividualEdge.data.contents,
                    startNodeConnected: false,
                };
            }

            // ====================================== handles iteration guard condition ======================================
            if (
                sourceNodeOfIndividualEdge.type === "iterationGuardNode" &&
                edge.sourceHandle !== null &&
                (edge.sourceHandle.includes("continue") ||
                    edge.sourceHandle.includes("break"))
            ) {
                let handle = edge.sourceHandle.includes("continue")
                    ? "isContinueHandleConnected"
                    : "isBreakHandleConnected";

                sourceNodeOfIndividualEdge.data.contents = {
                    ...sourceNodeOfIndividualEdge.data.contents,
                    [`${handle}`]: false,
                };
            }

            // ====================================== handles iteration guard condition ======================================

            reports = nodeValidationsLogic.validateNodeForChannel(
                sourceNodeOfIndividualEdge,
                state.localeSupportedByBot
            );
            // if (result !== undefined && result.flag === true) {
            // 	errorObject = {
            // 		flag: result.flag,
            // 		messages: result.messages,
            // 	};
            // }

            sourceNodeOfIndividualEdge.data.reports = reports;
        } // end of for loop (incoming edge iteration)

        // Deleting the outgoing edges of individual deleted node
        const allOutGoingEdgesOfDeletingNode = newEdges.filter(
            (edge) => edge.source === action.nodeId
        );
        for (let edge of allOutGoingEdgesOfDeletingNode) {
            // ----------------------  Only for LoopBackNode to delete target egde of loop back and update the content
            let targetNodeOfEdge = newNodes.find((node) => node.id === edge.target);
            // We are doing this to delete the loop back node handle
            const { nodes, edges } = traversalForDeletingLoopBack({
                nodes: newNodes,
                edges: newEdges,
                nodeId: edge.target,
                parentListOfSourceNode: targetNodeOfEdge.data.parentNodesIdArray,
            });
            newEdges = edges;
            newNodes = nodes;
            if (targetNodeOfEdge.type === "loopBackNode") {
                targetNodeOfEdge = {
                    ...targetNodeOfEdge,
                    data: {
                        ...targetNodeOfEdge.data,
                        contents: {
                            ...targetNodeOfEdge.data.contents,
                            isSourceNodeConnected: false,
                        },
                    },
                };
            }
            // -------------------------------------------------------

            newNodes = traverseToAllNodesForDeleteChannelIdAndParentId({
                edges: newEdges,
                nodes: newNodes,
                sourceNodeId: edge.source,
                targetNodeId: edge.target,
                sourceNodeHandle: edge.sourceHandle,
                connectedEdge: edge,
            });
        }

        newEdges = newEdges.filter((edge) => edge.source !== action.nodeId);
        newEdges = newEdges.filter((edge) => edge.target !== action.nodeId);

        //  remove the deleted the node from the node array
        let newNodesArray = newNodes.filter((node) => node.id !== action.nodeId);

        // remove all thh variable and suggestion list which was generate throught this node
        let newVariablesArray = state.variables.filter(
            (element) => element.nodeId !== action.nodeId
        );
        let newSuggetionVariablesArray = state.variableSuggestionList.filter(
            (element) => element.nodeId !== action.nodeId
        );

        //  Validate all the error nodes
        const {
            nodes: updatedNodes,
            warningCount,
            errorCount,
        } = validateAllNodes({
            edges: newEdges,
            stateVariables: state.variables,
            stateVariableSuggestionList: state.variableSuggestionList,
            nodes: newNodesArray,
            nodeId: action.nodeId,
            localeSupportedByBot: state.localeSupportedByBot,
        });

        // Update state
        const newState = {
            ...state,
            nodes: updatedNodes,
            edges: newEdges,
            currentlySelectedNode: null,
            latestSelectedNodeOrEdge: null,
            currentlySelectedEdge: null,
            variables: newVariablesArray,
            variableSuggestionList: newSuggetionVariablesArray,
            logCount: {
                errorCount: errorCount,
                warningCount: warningCount,
            },
        };
        return newState;
    } else {
        return state;
    }
};

export const deleteEdge = (state, action) => {
    const edgeToDelete = state.edges.find((edge) => edge.id === action.edgeId); // edge to delete

    let newEdgeArrayWithDeletedEdge = state.edges.filter(
        (edge) => edge.id !== action.edgeId
    ); // all edges wihtout deleted edges

    let newNodeArray = state.nodes.map((node) => {
        return {
            ...node,
            id: node.id,
            data: {
                ...node.data,
                belongsToChannel: node.data.belongsToChannel.map(
                    (belongsToChannel) => ({
                        ...belongsToChannel,
                        channelId: belongsToChannel.channelId,
                        refCount: belongsToChannel.refCount,
                    })
                ),
                parentNodesIdArray: node.data.parentNodesIdArray.map(
                    (parentNodesId) => ({
                        ...parentNodesId,
                        refCount: parentNodesId.refCount,
                    })
                ),
            },
            styles: { ...node.data.style },
        };
    });

    ///----------------------------  Steps for LoopBackNode when any node or edge remove then removing the source edge will autometically delete the target edge

    const targetNodeOfEdgeToDelete = state.nodes.find(
        (node) => node.id === edgeToDelete.target
    );
    const sourceNodeOfEdgeToDelete = state.nodes.find(
        (node) => node.id === edgeToDelete.source
    );
    const parentListOfSourceNode =
        targetNodeOfEdgeToDelete.data.parentNodesIdArray;

    if (
        !(
            edgeToDelete.sourceHandle != null &&
            edgeToDelete.sourceHandle.includes("continue_target_of_loop_back_node")
        )
    ) {
        const { nodes, edges } = traversalForDeletingLoopBack({
            nodes: newNodeArray,
            edges: newEdgeArrayWithDeletedEdge,
            nodeId: targetNodeOfEdgeToDelete.id,
            parentListOfSourceNode,
        });
        newNodeArray = nodes;
        newEdgeArrayWithDeletedEdge = edges;
    }
    const sourceNodeOfEdge = newNodeArray.find(
        (node) => node.id === edgeToDelete.source
    );
    if (
        edgeToDelete.sourceHandle != null &&
        edgeToDelete.sourceHandle.includes("exception")
    ) {
        if (checkIfCardInInputCard(sourceNodeOfEdge.type)) {
            newNodeArray = newNodeArray.map((node) => {
                if (
                    node.id === sourceNodeOfEdge.id &&
                    node.data.validation !== undefined &&
                    node.data.validation.validate === true
                ) {
                    let updatedNode = {
                        ...node,
                        data: {
                            ...node.data,
                            validation: {
                                ...node.data.validation,
                                isOnErrorHandleConnected: false,
                            },
                        },
                    };

                    const reports = nodeValidationsLogic.validateNodeForChannel(
                        updatedNode,
                        state.localeSupportedByBot
                    );
                    updatedNode.data.reports = reports;
                    return updatedNode;
                } else {
                    return node;
                }
            });
        }
    } else if (
        edgeToDelete.sourceHandle != null &&
        edgeToDelete.sourceHandle.includes("input_timeout")
    ) {
        if (checkIfCardInInputCard(sourceNodeOfEdge.type)) {
            newNodeArray = newNodeArray.map((node) => {
                if (
                    node.id === sourceNodeOfEdge.id &&
                    node.data.inputTimeout !== undefined &&
                    node.data.inputTimeout.enabled === true
                ) {
                    let updatedNode = {
                        ...node,
                        data: {
                            ...node.data,
                            inputTimeout: {
                                ...node.data.inputTimeout,
                                isTimeoutHandleConnected: false,
                            },
                        },
                    };
                    const reports = nodeValidationsLogic.validateNodeForChannel(
                        updatedNode,
                        state.localeSupportedByBot
                    );

                    updatedNode.data.reports = reports;
                    return updatedNode;
                } else {
                    return node;
                }
            });
        }
    }

    if (sourceNodeOfEdge.type === "startNode") {
        newNodeArray = newNodeArray.map((node) => {
            if (node.type === "startNode") {
                let startNode = {
                    ...node,
                    data: {
                        ...node.data,
                        contents: {
                            ...node.data.contents,
                            startNodeConnected: false,
                        },
                    },
                };
                const reports = nodeValidationsLogic.validateNodeForChannel(
                    startNode,
                    state.localeSupportedByBot
                );
                startNode.data.reports = reports;
                return startNode;
            } else {
                return node;
            }
        });
    }

    if (
        sourceNodeOfEdge.type === "iterationGuardNode" &&
        edgeToDelete.sourceHandle != null &&
        (edgeToDelete.sourceHandle.includes("continue") ||
            edgeToDelete.sourceHandle.includes("break"))
    ) {
        newNodeArray = newNodeArray.map((node) => {
            if (node.id === sourceNodeOfEdge.id) {
                let handle = edgeToDelete.sourceHandle.includes("continue")
                    ? "isContinueHandleConnected"
                    : "isBreakHandleConnected";
                let updatedNode = {
                    ...node,
                    data: {
                        ...node.data,
                        contents: {
                            ...node.data.contents,
                            [`${handle}`]: false,
                        },
                    },
                    style: "",
                };

                const reports = nodeValidationsLogic.validateNodeForChannel(
                    updatedNode,
                    state.localeSupportedByBot
                );
                updatedNode.data.reports = reports;
                return updatedNode;
            } else {
                return node;
            }
        });
    }

    //------ Only for LoopBack Node
    if (sourceNodeOfEdgeToDelete.type === "loopBackNode") {
        // ----------------  Making flag true for isTargetNodeConnected in LoopBackNode Content
        newNodeArray = newNodeArray.map((node) => {
            if (node.id === sourceNodeOfEdgeToDelete.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        contents: {
                            ...node.data.contents,
                            isSourceNodeConnected: false,
                        },
                    },
                };
            } else {
                return node;
            }
        });
    } else if (targetNodeOfEdgeToDelete.type === "loopBackNode") {
        const incomingEdgeOfLoopBackNodeExceptDeletedNode =
            newEdgeArrayWithDeletedEdge.find(
                (edge) => edge.target === targetNodeOfEdgeToDelete.id
            );
        if (!incomingEdgeOfLoopBackNodeExceptDeletedNode) {
            // ----------------  Making flag true for isTargetNodeConnected in LoopBackNode Content
            newNodeArray = newNodeArray.map((node) => {
                if (node.id === targetNodeOfEdgeToDelete.id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            contents: {
                                ...node.data.contents,
                                isTargetNodeConnected: false,
                            },
                        },
                    };
                } else {
                    return node;
                }
            });
        }
    }
    //-----------------------------------------------------------------------------------

    // Updating the child nodes for channel Array and parent Array !!!
    newNodeArray = traverseToAllNodesForDeleteChannelIdAndParentId({
        edges: newEdgeArrayWithDeletedEdge,
        nodes: newNodeArray,
        sourceNodeId: edgeToDelete.source,
        targetNodeId: edgeToDelete.target,
        sourceNodeHandle: edgeToDelete.sourceHandle,
        connectedEdge: edgeToDelete,
    });

    const {
        nodes: updatedNodes,
        errorCount,
        warningCount,
    } = validateAllNodes({
        edges: newEdgeArrayWithDeletedEdge,
        stateVariables: state.variables,
        stateVariableSuggestionList: state.variableSuggestionList,
        nodes: newNodeArray,
        localeSupportedByBot: state.localeSupportedByBot,
    });

    let newStateOfCurrentlySelectedNode = state.currentlySelectedNode;
    if (state.currentlySelectedNode && state.currentlySelectedNode.id) {
        newStateOfCurrentlySelectedNode = newNodeArray.find(
            (node) => node.id === state.currentlySelectedNode.id
        );
    }

    return {
        ...state,
        nodes: updatedNodes,
        edges: newEdgeArrayWithDeletedEdge,
        latestSelectedNodeOrEdge: null,
        logCount: {
            errorCount,
            warningCount,
        },
        currentlySelectedNode: newStateOfCurrentlySelectedNode
            ? newStateOfCurrentlySelectedNode
            : state.currentlySelectedNode,
        currentlySelectedEdge: null,
    };
};

export const duplicateNode = (state, action) => {
    const nodeToDuplicate = state.nodes.find((node) => node.id === action.nodeId);
    const startNode = state.nodes.find(node => node.type === 'startNode');
    const positionOfNewNode = { x: nodeToDuplicate.position.x + nodeToDuplicate.width + 75, y: nodeToDuplicate.position.y };
    const newNode = createNewNodeUsingExistingNode({ nodeToDuplicate, startNode, localeSupportedByBot: state.localeSupportedByBot, positionOfNewNode });

    const newNodesArray = [...state.nodes, newNode];

    // find node with no edges and give warning
    const { nodes: updatedNodes } = checkUnConnectedNodes({
        nodes: newNodesArray,
        edges: state.edges,
    });

    const newState = {
        ...state,
        nodes: updatedNodes,
        currentlySelectedNode: newNode,
        latestSelectedNodeOrEdge: { id: newNode.id, isNode: true },
        currentlySelectedEdge: null,
        logCount: {
            errorCount: calculateErrorCount(updatedNodes),
            warningCount: calculateWarningCount(updatedNodes),
        },
        duplicateNodeMakeCenter: newNode,
    };
    return newState;
};

export const updateNode = (state, action) => {
    let propertyNode = state.currentlySelectedNode;
    let nodeToUpdate = state.nodes.find((node) => node.id === action.nodeId);
    if (nodeToUpdate) {
        let newNodesArray = state.nodes.map((node) => {
            if (action.nodeId === node.id) {
                // we start with the target node of the first source node
                let currentNode = { ...node, data: action.data, style: action.style };
                // -------------------------------   Validation of target Node --------------------------

                const reports = nodeValidationsLogic.validateNodeForChannel(
                    currentNode,
                    state.localeSupportedByBot
                );

                currentNode.data.reports = reports;

                const newReports = suggestedVariableValidation(
                    currentNode,
                    state.variables,
                    state.variableSuggestionList,
                    state.localeSupportedByBot
                );

                currentNode.data.reports = newReports;
                // let date = new Date();

                //currentNode.data.validateCalledAt = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                propertyNode = currentNode;
                return propertyNode;
            } else {
                return node;
            }
        });

        return {
            ...state,
            nodes: newNodesArray,
            currentlySelectedNode: propertyNode,
            logCount: {
                errorCount: calculateErrorCount(newNodesArray),
                warningCount: calculateWarningCount(newNodesArray),
            },
        };
    } else {
        return state;
    }
};

export const addVariable = (state, action) => {
    const isVariableNameAlreadyExist = state.variables.find(
        (variable) => variable.variableName === action.variable.variableName
    );
    if (isVariableNameAlreadyExist === undefined) {
        let newVariableArray = state.variables;
        newVariableArray = [...newVariableArray, action.variable];

        let newNodeArray = traverseToAllChildNodesForValidations({
            edges: state.edges,
            stateVariables: newVariableArray,
            stateVariableSuggestionList: state.variableSuggestionList,
            nodes: state.nodes,
            nodeId: state.nodes[0].id,
            localeSupportedByBot: state.localeSupportedByBot,
        });

        return {
            ...state,
            nodes: newNodeArray,
            variables: newVariableArray,
            variableSuggestionList: state.variableSuggestionList,
            logCount: {
                errorCount: calculateErrorCount(newNodeArray),
                warningCount: calculateWarningCount(newNodeArray),
            },
        };
    } else {
        return state;
    }
};

export const updateVariableValue = (state, action) => {
    const newVariableArray = state.variables.map((variable) => {
        if (variable.variableId === action.variableId) {
            return {
                ...variable,
                value: action.newValue,
                variableType:
                    action.variableType !== undefined
                        ? action.variableType
                        : variable.variableType,
            };
        } else {
            return variable;
        }
    });

    let newNodeArray = traverseToAllChildNodesForValidations({
        edges: state.edges,
        stateVariables: newVariableArray,
        stateVariableSuggestionList: state.variableSuggestionList,
        nodes: state.nodes,
        nodeId: state.nodes[0].id,
        localeSupportedByBot: state.localeSupportedByBot,
    });

    return {
        ...state,
        nodes: newNodeArray,
        variables: newVariableArray,
        variableSuggestionList: state.variableSuggestionList,
        logCount: {
            errorCount: calculateErrorCount(newNodeArray),
            warningCount: calculateWarningCount(newNodeArray),
        },
    };
};

export const deleteVariable = (state, action) => {
    const variableExist = state.variables.find(
        (element) => element.nodeId === action.variable.nodeId
    );
    if (variableExist) {
        let newVariableArray = state.variables;
        let newVariableSuggestionlist = state.variableSuggestionList;
        if (action.variable.variableId === undefined) {
            newVariableArray = newVariableArray.filter(
                (element) => element.nodeId !== action.variable.nodeId
            );
        } else {
            newVariableArray = newVariableArray.filter(
                (element) => element.variableId !== action.variable.variableId
            );
            newVariableSuggestionlist = newVariableSuggestionlist.filter(
                (variableToIterate) =>
                    !(
                        variableToIterate.variable &&
                        variableToIterate.variable.variableId === action.variable.variableId
                    )
            );
        }

        let newNodeArray = traverseToAllChildNodesForValidations({
            edges: state.edges,
            stateVariables: newVariableArray,
            stateVariableSuggestionList: newVariableSuggestionlist,
            nodes: state.nodes,
            nodeId: state.nodes[0].id,
            localeSupportedByBot: state.localeSupportedByBot,
        });

        return {
            ...state,
            nodes: newNodeArray,
            variables: newVariableArray,
            variableSuggestionList: newVariableSuggestionlist,
            logCount: {
                errorCount: calculateErrorCount(newNodeArray),
                warningCount: calculateWarningCount(newNodeArray),
            },
        };
    } else {
        return state;
    }
};

export const updateEdgePosition = (state, action) => {
    return { ...state, edges: action.newEdges };
};

export const nodeListContextMenu = (state, action) => {
    return {
        ...state,
        nodeListContextMenu: {
            visible: action.visible,
            top: action.top,
            left: action.left,
            isAddNodeButtonClick: action.isAddNodeButtonClick,
        },
    };
};

/////////////////// checkUnConnectedNodes ////
export const checkUnConnectedNodes = (state, action) => {
    const updatedNodes = state.nodes.map(function (node) {
        if (node.type !== "startNode") {
            let nodeReports = { errors: {}, warnings: [] };
            if (node.data.reports && node.data.reports.warnings) {
                nodeReports = node.data.reports;
            }

            if (state.edges.find((edge) => edge.target === node.id)) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        reports: {
                            ...nodeReports,
                            warnings: {
                                ...nodeReports.warnings,
                                [reports_key.warning_key.HANGING_NODE.key]: {
                                    messages: [],
                                },
                            },
                        },
                    },
                };
                // return node;
            } else {
                let warningMessage = WorkflowErrors.HANGING_NODE_ERROR;
                return {
                    ...node,
                    data: {
                        ...node.data,
                        reports: {
                            ...nodeReports,
                            warnings: {
                                ...nodeReports.warnings,
                                [reports_key.warning_key.HANGING_NODE.key]: {
                                    messages: [warningMessage],
                                },
                            },
                        },
                    },
                };
            }
        } else {
            return node;
        }
    });
    return {
        ...state,
        nodes: updatedNodes,
    };
};

export const calculateErrorCount = (nodes) => {
    let errorCounter = 0;
    nodes.forEach((node) => {
        let messageLength = 0;
        if (node.data.reports && node.data.reports.errors) {
            if (Object.keys(node.data.reports.errors).length > 0) {
                const reportErrors = node.data.reports.errors;
                Object.keys(reportErrors).forEach((errorKey) => {
                    if (
                        reportErrors[errorKey].messages &&
                        reportErrors[errorKey].messages.length > 0
                    ) {
                        messageLength += reportErrors[errorKey].messages.length;
                    }
                });
            }
            errorCounter += messageLength;
        }
    });
    return errorCounter;
};

export const calculateWarningCount = (nodes) => {
    let warningCounter = 0;
    nodes.forEach((node) => {
        let messageLength = 0;
        if (node.data.reports && node.data.reports.warnings) {
            if (Object.keys(node.data.reports.warnings).length > 0) {
                let reportWarning = node.data.reports.warnings;
                Object.keys(reportWarning).forEach((warningKey) => {
                    if (
                        reportWarning[warningKey].messages &&
                        reportWarning[warningKey].messages.length > 0
                    ) {
                        messageLength += reportWarning[warningKey].messages.length;
                    }
                });
            }
            warningCounter += messageLength;
        }
        // if (
        // 	node.data.warning &&
        // 	node.data.warning.messages &&
        // 	node.data.warning.messages.length > 0
        // ) {
        // 	warningCounter += node.data.warning.messages.length;
        // }
    });
    return warningCounter;
};

export const addTargetHandleIdOnEndLoopConnect = (state, action) => {
    return {
        ...state,
        handleIdDataForEndLoopConnect: {
            handleFor: action.handleFor,
            handleId: action.handleId,
        },
    };
};

// this function will call when we connect nodes mean a new edgess created between nodes
export const connectNodes = (state, action) => {
    // action => { source,sourceHandle,target,targetHandle }
    // when user drop the connection on node except the handle it is dragging
    // so here we made a programmitically connection between start node and end node
    // where start node is a node where user starts the connection ( edge drag start )
    // end node is a node where user drop the connection (edge drag stop)

    if (
        action.source !== undefined &&
        action.target !== undefined &&
        action.source !== action.target
    ) {
        // Creating params to collect all the data that we need in further code
        // This helps when source and target comes in some other way in react flow different versions
        const params = {
            source: action.source, // nodeId - Source Node
            sourceHandle: action.sourceHandle, // it can be null in case of 1 source n node and may not be null in case multiple sources in node (buttons)
            target: action.target, // nodeId - Target Node
            targetHandle: action.targetHandle, // it will always by null
        };

        // reactflow allow one source to be connected to multiple targets, but in our case we want one source one target only
        // this check is to find out if the source is already connected to one target
        // here we will try to find if edges array already has an edge in it that has a same source as that of the new edge
        // is the same edge data is not found we will let it go further for establishing connection
        const sourceMatchedInEdges = state.edges.find(
            (edge) => edge.source === params.source
        );

        if (sourceMatchedInEdges) {
            // if found

            if (params.sourceHandle == null)
                // this tells us that our node is single source node hence there is no need to go further to check multiple sources
                return state;

            // this is to find out handle when there are multiple sources in the node (buttons and list, etc.)
            const checkForMultipleSourceHandle = state.edges.find(
                (edge) => edge.sourceHandle === params.sourceHandle
            );
            // if already connected we return the same state otherwise we will go further to establish connection
            if (checkForMultipleSourceHandle) {
                return state;
            }
        }

        const newCreatedEdge = {
            id: generateId(),
            source: params.source,
            target: params.target,
            sourceHandle: params.sourceHandle,
            targetHandle: params.targetHandle,
            type: "unidirectionEdge",
            data: {},
            zIndex: 0,
        };

        // using ids find source and taget node objects in the existing nodes array in the state
        const sourceNode = state.nodes.find((node) => node.id === params.source);
        const targetNode = state.nodes.find((node) => node.id === params.target);

        let newEdges = state.edges,
            newNodes = state.nodes;

        // the target node cannot be parent of source node in many cases
        // but in some case target node can be parent of source node (the case is loopback)
        // we are finding whther target node exists in parent nodes array of source node
        const isTargetNodeParentOfSourceNode =
            sourceNode.data.parentNodesIdArray.find(
                (parent) => parent.parentId === targetNode.id
            );
        if (!isTargetNodeParentOfSourceNode) {
            // Here we know that the target node is not parent of source node and if source node is type of loopBackNode we will directely return
            if (sourceNode.type === "loopBackNode") {
                return state;
            }

            // Target node is NOT the Parent of Source Node

            // Assigning New Edges
            newEdges = [...state.edges, newCreatedEdge];

            // Traverse to pass parent ID and channel IDs
            newNodes = traverseToAllNodesForAddChannelIdAndParentId({
                state: { nodes: state.nodes, edges: newEdges },
                sourceNodeId: params.source,
                targetNodeId: params.target,
                startNodeHandle: params.sourceHandle,
                connectedEdge: newCreatedEdge,
            });

            // This condition demands that when loopback is becoming target of some node then loopback's source must be connected to the parent of itself
            if (targetNode.type === "loopBackNode") {

                // Checking for incoming edge of loopBack node
                // If there is already incoming edge to the loopBack then we will not allow user to create more incoming edge.
                // We are allowing only one incoming edge for loopBackNode
                const isTargetLoopBackNodeAlreadyConnected = state.edges.find(edge => edge.target === targetNode.id);
                if (isTargetLoopBackNodeAlreadyConnected) {
                    return state;
                }


                // Making flag true for isTargetNodeConnected in LoopBackNode Content.
                // this helps to show error in loopback node
                // We are changing only single node but still we to do map because we want to get new nodes array for updating UI
                // Assigning newNodes
                newNodes = newNodes.map((node) =>
                    node.id === targetNode.id
                        ? {
                            ...node,
                            data: {
                                ...node.data,
                                contents: {
                                    ...node.data.contents,
                                    isTargetNodeConnected: true, // ******** VERY IMP
                                },
                            },
                        }
                        : node
                );
            }
        } else {
            // Target node is the Parent of Source Node

            // if Source node is type of loopback node
            if (sourceNode.type === "loopBackNode") {
                newCreatedEdge.type = "loopBackEdge";
                // Assigning newEdges
                newEdges = [...state.edges, newCreatedEdge];


                const newTraversalObject = new TraversalClass({ nodes: state.nodes, edges: newEdges });

                // This is to find out that loopback must have an valid card if not it becomes dangerous as it will go in never ending loop and will keep running without stopping
                const isValidCardTypePresentInLoopBackCycle = newTraversalObject.checkIsValidCardTypePresentInLoopBackCycle({
                    loopBackNodeId: sourceNode.id,
                    validCardTypeArrayForLoopBackCycle: validCardTypeAllowedInLoopBackCycle
                });

                // Assigning newNodes
                newNodes = state.nodes.map((node) =>
                    node.id === sourceNode.id
                        ? {
                            ...node,
                            data: {
                                ...node.data,
                                contents: {
                                    ...node.data.contents,
                                    isSourceNodeConnected: true, // **** IMP
                                    isValidCardTypePresentInLoopBackCycle,
                                },
                            },
                        }
                        : node
                );
            } else {
                //  if Source node is NOT type of loopback node then we will not do anything
            }
        }

        // The startnode must have some node as target
        if (sourceNode.type === "startNode") {
            // Assigning newNodes
            newNodes = newNodes.map((node) => {
                if (node.type === "startNode") {
                    // if it is start node
                    let startNode = {
                        ...node,
                        data: {
                            ...node.data,
                            contents: {
                                ...node.data.contents,
                                startNodeConnected: true,
                            },
                        },
                    };
                    const reports = nodeValidationsLogic.validateNodeForChannel(
                        startNode,
                        state.localeSupportedByBot
                    );
                    return {
                        ...startNode,
                        data: {
                            ...startNode.data,
                            reports,
                        },
                    };
                } else {
                    // if it is not the start node
                    return node;
                }
            });
        }

        // This is to OnError, OnTimeout buttons if Inut Cards only
        if (
            checkIfCardInInputCard(sourceNode.type) &&
            params.sourceHandle != null &&
            params.sourceHandle.includes("exception")
        ) {
            newNodes = newNodes.map((node) => {
                if (
                    node.id === sourceNode.id &&
                    node.data.validation !== undefined &&
                    node.data.validation.validate === true
                ) {
                    let updatedNode = {
                        ...node,
                        data: {
                            ...node.data,
                            validation: {
                                ...node.data.validation,
                                isOnErrorHandleConnected: true,
                            },
                        },
                        style: "",
                    };
                    const reports = nodeValidationsLogic.validateNodeForChannel(
                        updatedNode,
                        state.localeSupportedByBot
                    );
                    updatedNode.data.reports = reports;
                    return updatedNode;
                } else {
                    return node;
                }
            });
        } else if (
            checkIfCardInInputCard(sourceNode.type) &&
            params.sourceHandle != null &&
            params.sourceHandle.includes("input_timeout")
        ) {
            newNodes = newNodes.map((node) => {
                if (
                    node.id === sourceNode.id &&
                    node.data.inputTimeout !== undefined &&
                    node.data.inputTimeout.enabled === true
                ) {
                    let updatedNode = {
                        ...node,
                        data: {
                            ...node.data,
                            inputTimeout: {
                                ...node.data.inputTimeout,
                                isTimeoutHandleConnected: true,
                            },
                        },
                        style: "",
                    };
                    const reports = nodeValidationsLogic.validateNodeForChannel(
                        updatedNode,
                        state.localeSupportedByBot
                    );

                    updatedNode.data.reports = reports;
                    return updatedNode;
                } else {
                    return node;
                }
            });
        }

        // Iteration Guard : Continue Handle and Break handle must be connected if Iterator Guard is a source
        if (
            sourceNode.type === "iterationGuardNode" &&
            params.sourceHandle != null &&
            (params.sourceHandle.includes("continue") ||
                params.sourceHandle.includes("break"))
        ) {
            newNodes = newNodes.map((node) => {
                if (node.id === sourceNode.id) {
                    let handle = params.sourceHandle.includes("continue")
                        ? "isContinueHandleConnected"
                        : "isBreakHandleConnected";
                    let updatedNode = {
                        ...node,
                        data: {
                            ...node.data,
                            contents: {
                                ...node.data.contents,
                                [`${handle}`]: true,
                            },
                        },
                        style: "",
                    };
                    const reports = nodeValidationsLogic.validateNodeForChannel(
                        updatedNode,
                        state.localeSupportedByBot
                    );
                    updatedNode.data.reports = reports;
                    return updatedNode;
                } else {
                    return node;
                }
            });
        }

        const {
            nodes: updatedNodes,
            errorCount,
            warningCount,
        } = validateAllNodes({
            edges: newEdges,
            stateVariables: state.variables,
            stateVariableSuggestionList: state.variableSuggestionList,
            nodes: newNodes,
            nodeId: sourceNode.id,
            localeSupportedByBot: state.localeSupportedByBot,
        });

        // Getting latest updated state of currently selected node for showing propery menu when property menu is open
        // if property menu is open then state.currentlySelectedNode then it will not be null
        let newStateOfCurrentlySelectedNode = null;
        if (state.currentlySelectedNode && state.currentlySelectedNode.id) {
            newStateOfCurrentlySelectedNode = updatedNodes.find(
                (node) => node.id === state.currentlySelectedNode.id
            );
        }

        return {
            ...state,
            nodes: updatedNodes,
            edges: newEdges,
            startConnectionNodeDataOnConnectionStart: {},
            endConnectionNodeDataOnMouseHover: {},
            handleIdDataForEndLoopConnect: {},
            logCount: {
                errorCount: errorCount,
                warningCount: warningCount,
            },
            currentlySelectedNode: newStateOfCurrentlySelectedNode,
            currentlySelectedEdge: null,
        };
    } else {
        // if source node and target are not same nodes
        return state;
    }
};

export const updateChannelArrayOfNodes = (state, action) => {
    try {
        let nodes = state.nodes;

        // Find start node
        const startNode = nodes.find((node) => node.type === "startNode");

        // Create initial belongsToArray
        let belongsToChannelsArrayGlobal = [];
        if (action.payload.channels.length > 0) {
            const filteredChannels = action.payload.channels.filter((channel) =>
                checkIsChannelValidForChannelSwitchNode(
                    getChannelIdByChannelName(channel)
                )
            );
            belongsToChannelsArrayGlobal = filteredChannels.map((channel) => {
                return {
                    channelId: getChannelIdByChannelName(channel),
                    refCount: 1,
                    channelSwitchNodeId: startNode.id,
                };
            });
        }

        // At this stage we have belongsToArray generated
        // ============================= WE ARE ASSINING THE NEWLY GENERATED BELONGSTOARRAY TO START CARD AND CHANNEL SWITCHCARD ====================
        const updatedNodes = nodes.map((node, index) => {
            // If start node is encountered then we have assiging the newly generated belongsToArray to it
            if (node.type === "startNode") {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        belongsToChannel: belongsToChannelsArrayGlobal,
                        // parentNodesIdArray: []
                    },
                };
            }

            // if channel switch node is encounter we have asssigning the newlu generated belongsToArray to it
            if (node.type === "channelSwitchNode") {
                const channelsList = belongsToChannelsArrayGlobal.map((channel) => {
                    const filterChannelFromPreviousData = node.data.channels.find(
                        (ch) => ch.channelId === channel.channelId
                    );
                    if (filterChannelFromPreviousData) {
                        return filterChannelFromPreviousData;
                    } else {
                        return {
                            channelId: channel.channelId,
                            channelName: channel.channelId,
                            handleId: `${node.id}_${channel.channelId}`,
                            isActive: true,
                        };
                    }
                });
                return {
                    ...node,
                    data: {
                        ...node.data,
                        channels: channelsList,
                    },
                };
            }
            return {
                ...node,
                data: {
                    ...node.data,
                    belongsToChannel: [],
                    validation:
                        node.data.validation && node.data.validation.validate === true
                            ? node.data.validation
                            : generateNodeValidation(node.type),
                },
            };
        });
        // ============================= WE ARE ASSINING THE NEWLY GENERATED BELONGSTOARRAY TO START CARD AND CHANNEL SWITCHCARD ====================

        // find initial edge of startnode
        const connectedEdge = state.edges.find(
            (edge) => edge.source === startNode.id
        );
        if (connectedEdge) {
            const targetNodeId = connectedEdge.target;

            let queue = new Queue(); // FIFO

            /*
                    taragetNodeId:  passing the target node of sourceNode---->targetNode
                    sourceNodeId:  passing the source node of sourceNode---->targetNode
                    belongsToChannelArrayToAdd:  for passing the belogsToChannelArray based on the sourceNode
                      targetNodeId:  passing the target node of sourceNode---->targetNode
                      sourceNodeId:  passing the source node of sourceNode---->targetNode
                      belongsToChannelArrayToAdd:  for passing the belogsToChannelArray based on the sourceNode
                    */
            queue.enqueue({
                targetNodeId: targetNodeId,
                sourceNodeId: startNode.id,
                belongsToChannelArrayToAdd: belongsToChannelsArrayGlobal,
                connectedEdge,
            });

            const tarversedNode = {};
            // sourcenode_targetnode_parentnode
            tarversedNode[startNode.id] = true;

            let traversedEdges = {};

            let nodesWhichAreNotTraversed = [];

            // --------------- MAJOR STEP ----------------------------------------------------------
            // writing first node id to all the subsequent node as parent

            let traversedNodes = {};

            while (!queue.isEmpty()) {
                const {
                    targetNodeId,
                    sourceNodeId,
                    belongsToChannelArrayToAdd,
                    connectedEdge,
                } = queue.dequeue();
                let belongsToChannelToPassInQueue = [];
                // we start with the target node of the first source node
                let targetNode = updatedNodes.find((node) => node.id === targetNodeId);
                let sourceNode = updatedNodes.find((node) => node.id === sourceNodeId);
                if (
                    sourceNode.data.parentNodesIdArray.find(
                        (parentNodeId) => parentNodeId.parentId === targetNode.id
                    )
                ) {
                    continue;
                }

                if (targetNode.type === "channelSwitchNode") {
                    // for the channelSwitch node and the child of channelSwitch node the belongs to channel array will be different
                    belongsToChannelToPassInQueue = [];
                } else {
                    // checking if the source Node is ChannelSwitchNode if yes then we will compare and pass previous channel data of node else new data.
                    if (sourceNode.type === "channelSwitchNode") {
                        sourceNode.data.channels.forEach((channel) => {
                            if (connectedEdge.sourceHandle === channel.handleId) {
                                belongsToChannelToPassInQueue.push({
                                    channelId: channel.channelId,
                                    refCount: 1,
                                    channelSwitchNodeId: sourceNode.id,
                                });
                            }
                        });
                    } else {
                        // for the other than channelSwitch node the belongToChannel Array will be the same as source array
                        belongsToChannelToPassInQueue = belongsToChannelArrayToAdd;
                    }
                    // ------------------------ PASSING CHANNEL INFO ----------------------------------------------------------
                    // Taking opportunity of first traversal to transfer channel information to all nodes
                    for (let belongToChannelOfSourceNode of belongsToChannelToPassInQueue) {
                        const indexBelongToChannelObjectInTargetNode =
                            targetNode.data.belongsToChannel.findIndex(
                                (channelInTarget) =>
                                    channelInTarget.channelId ===
                                    belongToChannelOfSourceNode.channelId &&
                                    channelInTarget.channelSwitchNodeId ===
                                    belongToChannelOfSourceNode.channelSwitchNodeId
                            );
                        if (indexBelongToChannelObjectInTargetNode !== -1) {
                            targetNode.data.belongsToChannel[
                                indexBelongToChannelObjectInTargetNode
                            ].refCount += belongToChannelOfSourceNode.refCount;
                        } else {
                            targetNode.data.belongsToChannel.push({
                                channelId: belongToChannelOfSourceNode.channelId,
                                channelSwitchNodeId:
                                    belongToChannelOfSourceNode.channelSwitchNodeId,
                                refCount: belongToChannelOfSourceNode.refCount,
                            });
                        }
                    }
                }

                // --------------------------------------------------------------------

                // HERE WE ARE CHECKING THAT ALL INCOMING EDGES OF CURRENTNODE ARE TRAVERSED IF YES THEN ONLY WE WILL ADD ALL OUTGOING EDGES OF CURRENTNODE
                // OTHERWISE WE WILL NOT ADD IT IN QUEUE

                // Here we are assuming all incoming edges of currentNode is traversed
                let isAllInComingEdgesTraversed = true;

                // first we will add edge between previous Node and currentNode into traversedEdges
                traversedEdges[connectedEdge.id] = true;

                const allInComingEdges = findAllInCommingEdgesOfNode(
                    state.edges,
                    targetNode.id
                );

                for (let index = 0; index < allInComingEdges.length; index++) {
                    const incomingEdge = allInComingEdges[index];
                    // checking incoming edge is traversed or not and incoming edge type is not of loopBackCard => having handle id 'continue_target_of_loop_back_node'
                    if (
                        !traversedEdges[incomingEdge.id] &&
                        incomingEdge.sourceHandle.includes(
                            "continue_target_of_loop_back_node"
                        ) === false
                    ) {
                        // since all the edges of current node is not traversed so we are not adding it in the queue.
                        // we will wait for this condition to be satisfied in later
                        isAllInComingEdgesTraversed = false;
                        break;
                    }
                }

                if (isAllInComingEdgesTraversed && !traversedNodes[targetNode.id]) {
                    // here we know that all incoming edges of current node is traversed and we are going to traverse the current node
                    // thats why we will remove current node from nodesWhichAreNotTraversed array
                    nodesWhichAreNotTraversed = nodesWhichAreNotTraversed.filter(
                        (nodeId) => nodeId === targetNode.id
                    );
                    const allOutGoingEdgesOfCurrentNode = findAllOutGoingEdgesOfNode(
                        state.edges,
                        targetNode.id
                    );
                    if (allOutGoingEdgesOfCurrentNode.length === 0) {
                        // That means we have no outgoing edges of current node and this is the last node of the flow
                        continue;
                    }

                    for (
                        let index = 0;
                        index < allOutGoingEdgesOfCurrentNode.length;
                        index++
                    ) {
                        const connectedEdge = allOutGoingEdgesOfCurrentNode[index];
                        queue.enqueue({
                            targetNodeId: connectedEdge.target,
                            sourceNodeId: connectedEdge.source,
                            belongsToChannelArrayToAdd: targetNode.data.belongsToChannel,
                            connectedEdge,
                        });
                    }

                    traversedNodes[targetNode.id] = true;
                }

                if (isAllInComingEdgesTraversed === false) {
                    // Here we know that one or more incoming edge of current node is not traversed yet
                    // so we will add current node in nodesWhichAreNotTraversed Array
                    nodesWhichAreNotTraversed = Array.from(
                        new Set([...nodesWhichAreNotTraversed, targetNode.id])
                    );
                }

                if (queue.isEmpty() && nodesWhichAreNotTraversed.length > 0) {
                    const currentNodeId = nodesWhichAreNotTraversed.shift();
                    const allOutGoingEdgesOfCurrentNode = findAllOutGoingEdgesOfNode(
                        state.edges,
                        currentNodeId
                    );
                    if (allOutGoingEdgesOfCurrentNode.length === 0) {
                        // That means we have no outgoing edges of current node and this is the last node of the flow
                        continue;
                    }
                    // now we have edges of currentNode
                    // find all nodes and update the belongs to arrary
                    for (
                        let index = 0;
                        index < allOutGoingEdgesOfCurrentNode.length;
                        index++
                    ) {
                        const connectedEdge = allOutGoingEdgesOfCurrentNode[index];
                        queue.enqueue({
                            targetNodeId: connectedEdge.target,
                            sourceNodeId: connectedEdge.source,
                            belongsToChannelArrayToAdd: targetNode.data.belongsToChannel,
                            connectedEdge,
                        });
                    }
                    traversedNodes[currentNodeId] = true;
                }
            }
        }
        return updatedNodes;
    } catch (error) {
        console.log(error);
    }
};

export const updateNodesErrorAndWarning = async ({
    nodes,
    edges,
    localeSupportedByBot,
    stateVariables,
    stateVariableSuggestionList,
}) => {
    const {
        nodes: updatedNodes,
    } = validateAllNodes({
        nodes,
        edges,
        localeSupportedByBot,
        stateVariables,
        stateVariableSuggestionList,
    });
    return updatedNodes;
};

/**
 * Validate all the renderable nodes for locale
 * @param {} param0
 * @returns
 */
export const updateLocaleErrorOnInitialEditorDataLoad = ({
    nodes,
    localeSupportedByBot,
}) => {
    const renderableNodes = [
        "plainMessageTextNode",
        "questionNode",
        "buttonNode",
        "listNode",
        "carouselNode",
        "mediaNode",
        "mediaInputNode",
        "locationInputNode",
        "dynamicButtonNode",
        "dynamicListNode",
        "whatsAppFlowsNode",
        "whatsAppTemplateNode",
        "whatsAppPaymentNode",
        "whatsAppCatalogNode",
        "rcsStandAloneNode",
    ];
    return nodes.map((node) => {
        if (renderableNodes.includes(node.type) || node.type === "setLocaleNode") {
            const result = nodeValidationsLogic.validateNodeForChannel(
                node,
                localeSupportedByBot
            );

            // if (result && result.flag === true) {
            // 	errorObject = {
            // 		flag: result.flag,
            // 		messages: result.messages,
            // 	};
            // }
            return {
                ...node,
                data: {
                    ...node.data,
                    reports: result,
                },
                style: nodeErrorStyle,
            };
        }
        return node;
    });
};

export const markVariableAsSentiveVariable = (state, action) => {
    const newVariableArray = state.variables.map((variable) => {
        if (variable.variableId === action.variableId) {
            return {
                ...variable,
                isSensitiveVariable: action.checked,
            };
        } else {
            return variable;
        }
    });
    // we dont need to update variable array
    return {
        ...state,
        variables: newVariableArray,
    };
};

export const findNode = (nodes, nodeId) => {
    return nodes.find((node) => node.id === nodeId);
};

export const findAllOutGoingEdgesOfNode = (edges, nodeId) => {
    return edges.filter((edge) => edge.source === nodeId);
};

export const findAllInCommingEdgesOfNode = (edges, nodeId) => {
    return edges.filter((edge) => edge.target === nodeId);
};

export const deleteChannelSwitchEdgesWhoseIntegrationNotAddedOrDeleted = ({
    nodes,
    edges,
    integratedChannels,
    allIntegrations,
}) => {
    try {
        const filteredIntegratedChannels = integratedChannels
            .filter((channel) =>
                checkIsChannelValidForChannelSwitchNode(
                    getChannelIdByChannelName(channel)
                )
            )
            .map((channel) => getChannelIdByChannelName(channel.toLowerCase()));

        const filteredAllIntegrationsChannels = allIntegrations
            .filter((integration) =>
                checkIsChannelValidForChannelSwitchNode(
                    getChannelIdByChannelName(integration.name ?? "")
                )
            )
            .map((integration) =>
                getChannelIdByChannelName(integration.name.toLowerCase())
            );

        const notIntegratedChannels = filteredAllIntegrationsChannels.filter(
            (channel) => !filteredIntegratedChannels.includes(channel)
        );

        let filteredEdges = edges;

        nodes.forEach((node) => {
            if (node.type === "channelSwitchNode") {
                for (let index = 0; index < notIntegratedChannels.length; index++) {
                    const channel = notIntegratedChannels[index];
                    const handleId = `${node.id}_${channel}`;
                    filteredEdges = filteredEdges.filter(
                        (edge) => edge.sourceHandle !== handleId
                    );
                }
            }
        });

        return filteredEdges;
    } catch (error) {
        return edges;
    }
};

export const updateStartNodeErrorCount = async ({ nodes, botId, botVersion }) => {
    let updatedNodes = [];
    try {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];

            // update error count in startNode
            if (node.type === "startNode" && botVersion !== undefined) {
                if (
                    node.data &&
                    node.data.subWorkFlowKeyword &&
                    node.data.subWorkFlowKeyword.length !== 0
                ) {
                    // TODO: Change the implementation @suraj-leadows
                    // TODO: Instead of fetching all the workflows of bot, fetch only used subworkflow and projection should be {errorCount:1}
                    const getAllWorkflowResult = await workflowService.getWorkflowListOfBotFormServerAsync(botId, botVersion);
                    //botVersion getting empty 
                    if (getAllWorkflowResult && getAllWorkflowResult.length > 0) {
                        const subWorkFlowArray = []
                        for (let index = 0; index < node.data.subWorkFlowKeyword.length; index++) {
                            const element = node.data.subWorkFlowKeyword[index];
                            if (element.workflowId !== "") {
                                for (let index = 0; index < getAllWorkflowResult.length; index++) {
                                    const responseApi = getAllWorkflowResult[index];
                                    if (responseApi._id === element.workflowId) {
                                        const subWorkFlowKeywordObject = {
                                            ...element,
                                            errorCount: responseApi.errorCount
                                        }
                                        subWorkFlowArray.push(subWorkFlowKeywordObject);
                                        break;
                                    }
                                }
                            } else {
                                subWorkFlowArray.push(element);
                            }
                        }
                        node.data.subWorkFlowKeyword = subWorkFlowArray;
                    }

                }
            }
            updatedNodes.push(node);
        };
    } catch (error) {
        console.log(JSON.stringify(error));
    }
    return updatedNodes;
};

export const updateLatestSubflowNodesErrorCount = async ({ nodes, workflowId, botId = "", workflowType = workflowTypes.BOT_WORKFLOW, containerId = '' }) => {
    let updatedNodes = [];
    try {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (node.type === "subFlowNode") {
                if (
                    node.data.contents &&
                    node.data.contents.subFlow &&
                    node.data.contents.subFlow.id
                ) {
                    const subWorkflowValidation = new SubWorkflowValidation(botId, containerId, workflowType);
                    let result = await subWorkflowValidation.fetchSubWorkflowData({ subWorkflowId: node.data.contents.subFlow.id, projection: { name: 1, errorCount: 1, usedSubflowIdArray: 1 } });

                    if (result && result.data) {
                        if (result.data.errorCount) {
                            let errorsInSubflow = result.data.errorCount !== undefined ? result.data.errorCount : 0;
                            node.data.contents.subFlow.errorsInSubflow = errorsInSubflow;
                        }

                        const { isRecursive, recursiveSubworkflowName } = await subWorkflowValidation.findRecursiveCallAndGenerateMessage({
                            usedSubWorkflowIdArray: result.data.usedSubflowIdArray,
                            subWorkflowId: node.data.contents.subFlow.id,
                            subWorkflowName: result.data.name,
                            workflowId,
                        });
                        node.data.contents.subFlowRecursive = { isRecursive, recursiveSubworkflowName }
                    }
                }
            }
            updatedNodes.push(node);
        }
    } catch (error) {
        console.log(JSON.stringify(error));
    }
    return updatedNodes;
};

export const saveProcessWorkflowData = async (state, saveType) => {
    let snackbar = { open: true, severity: "", message: "" };
    try {
        const processId = state.botId;
        const workflowId = state.workflowId;

        const { usedSubflowIdArray, errorCount } = findUsedSubflowsInNodesArray({
            nodes: state.nodes,
            errorCount: state.logCount.errorCount,
        });

        const { nodes, edges } = removeTemporaryDataFromNodesAndEdges(state.nodes, state.edges);
        const response =
            await processWorkflowService.updateProcessWorkflowDataAsync({
                processId,
                workflowId,
                nodes: nodes,
                edges: edges,
                variables: state.variables,
                variableSuggestionList: state.variableSuggestionList,
                errorCount,
                usedSubflowIdArray,
                defaultLocale: state.defaultLocale,
                localeSupportedByBot: state.localeSupportedByBot,
                workflowSaveType: saveType,
            });
        return handleResponse(response, snackbar)
    } catch (error) {
        snackbar.severity = "error";
        snackbar.message = `Server Exception ${error}`;
    } finally {
        return snackbar;
    }
};


export const saveProcessSubWorkflowData = async (state, saveType, container) => {
    let snackbar = { open: true, severity: "", message: "" };
    try {
        const workflowId = state.workflowId;

        const { usedSubflowIdArray, errorCount } = findUsedSubflowsInNodesArray({
            nodes: state.nodes,
            errorCount: state.logCount.errorCount,
        });

        const response =
            await processWorkflowService.updateProcessSubWorkflowDataAsync({
                workflowId,
                nodes: state.nodes,
                edges: state.edges,
                variables: state.variables,
                variableSuggestionList: state.variableSuggestionList,
                errorCount,
                usedSubflowIdArray,
                workflowSaveType: saveType,
                container,
            });
        return handleResponse(response, snackbar);
    } catch (error) {
        snackbar.severity = "error";
        snackbar.message = `Server Exception ${error}`;
    } finally {
        return snackbar;
    }
};

const handleResponse = (response, snackbar) => {
    if (response) {
        if (
            response.data &&
            (response.data.status === "SAVED" ||
                response.data.message === "updated")
        ) {
            snackbar.severity = "success";
            snackbar.message = response.data.message;
        } else if (response.response) {
            if (response.response.data && response.response.data.message) {
                snackbar.severity = "error";
                snackbar.message = response.response.data.message;
            } else {
                snackbar.severity = "error";
                snackbar.message = response.response.statusText;
            }
        } else {
            snackbar.severity = "error";
            snackbar.message = response.message;
        }
    } else {
        snackbar.severity = "error";
        snackbar.message = `Server is not reachable.`;
    }
    return snackbar;
}

export const deployProcessWorkflow = async (state) => {
    try {
        const botId = state.botId;
        const workflowId = state.workflowId;

        const response = await processWorkflowService.deployBotAsync({
            botId,
            workflowId,
            nodes: state.nodes,
            edges: state.edges,
            variables: state.variables,
            variableSuggestionList: state.variableSuggestionList,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const formatWorkflowType = (workflowType = '') => {
    const splittedWorkflowType = workflowType.toLowerCase().split('_');
    return splittedWorkflowType.join(' ');
}
/**
 * This method check and validate the node type is supported or not
 * @param {*} param0 
 * @returns 
 */
export const validateNodeTypeAndGenerateError = ({ nodes, validNodeList, workflowType }) => {
    try {
        const updatedNodes = nodes.map((node) => {
            // Here eliminating start node
            if (node.type === 'startNode') {
                return node;
            }
            const validNode = validNodeList.find(validNode => validNode.type === node.type);
            if (validNode) {
                return node;
            }
            const nodeNotSupportMessage = `${node.data.title} is not supported in ${formatWorkflowType(workflowType)}.`;
            return {
                ...node,
                data: {
                    ...node.data,
                    isNodeSupported: false,
                    nodeNotSupportMessage,
                    reports: {
                        ...node.data.reports,
                        errors: {
                            [reports_key.error_key.NODE_TYPE.key]: { messages: [nodeNotSupportMessage] }
                        }
                    }
                }
            }
        });
        return updatedNodes;
    } catch (error) {
        console.log({ statue: 'FAILED', message: 'Error occured during validateNodeTypeAndGenerateError function execution.', error });
        return nodes;
    }
};
