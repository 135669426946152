import { generateId } from "../../../../../redux/builderLogic/builderStoreLogic";
import { NODE_TYPES } from "../../../../../utils/Constants/WorkflowConstants";
import { cardList } from "./cardList";

// ============================ READ THIS ============================
//  here generating basic node content based on node type
// two switch case , first handle all nodes node content (all renderable nodes wrong content)
// second handle content for all renderable nodes based on locales
// #TODO: Remove all the renderable node type cases from first switch case
// ============================ READ THIS ============================
export const getNodeContent = (nodeType, nodeId, locales, version = "1.0") => {
    let content = null;
    switch (nodeType) {
        case "plainMessageTextNode": {
            content = "";
            break;
        }
        case "questionNode": {
            content = "";
            break;
        }

        case "listNode":
            content = {
                openButtonText: "",
                bodyText: "",
                list: [],
            };
            break;
        case "loopBackNode":
            content = {
                isSourceNodeConnected: false,
                isTargetNodeConnected: false,
                isValidCardTypePresentInLoopBackCycle: false,
            };
            break;

        case "optionNode":
            content = {
                header: "",
                options: [],
            };
            break;

        case "buttonNode":
            content = {
                fileDisplayNameForDocument: "",
                isMediaRender: null,
                mediaType: "",
                mediaUrl: "",
                headerText: "",
                bodyText: "",
                footerText: "",
                buttons: [],
                mediaFromType: "",
                isUrlByVariable: false,
                buttonType: "text",
                buttonUrl: ""
            };
            break;

        case "mediaNode":
            content = {
                isDataFromUrl: null,
                mediaType: null,
                media: {
                    fileUrl: null,
                    displayname: null,
                    mediaType: null,
                    mediaFromType: null,
                    selectedMediaUrlFrom: "byUpload", // check wheather from where the url is coming from
                },
                caption: "", //max 1024 char
                fileDisplayNameForDocument: "",
            };
            break;

        case "carouselNode":
            content = carouselNodeContents.getContentByVersion(version, nodeId);
            break;

        case "mediaInputNode":
            content = {
                text: "",
            };
            break;

        case "webhookNode":
            content = webhookNodeContents.getContentByVersion(version, nodeId);
            break;

        case "conditionNode":
            content = {
                checkType: null,
                conditions: [
                    {
                        compareToValue: "",

                        conditionType: "LESSTHAN",

                        isCompareWithConstant: true,
                        compareWithValue: "",
                    },
                    {
                        compareToValue: "",
                        conditionType: "LESSTHAN",
                        isCompareWithConstant: true,
                        compareWithValue: "",
                    },
                ],
            };
            break;

        case "iterationGuardNode":
            content = {
                maxValue: null,
                isMaxValueFromVariable: false,
                nextNodeOnContinue: `${nodeId}_continue`,
                nextNodeOnBreak: `${nodeId}_break`,
                isContinueHandleConnected: false,
                isBreakHandleConnected: false,
            };
            break;

        case "scriptNode":
            content = scriptNodeContents.getContentByVersion(version, nodeId);
            break;

        case "channelSwitchNode":
            content = [];
            break;

        case "switchConditionNode":
            content = {
                variableToCheckCondition: null,
                conditions: [], //{ id: string, conditionType: string, compareValue: string, isCompareWithConstant: boolean, handleId: string }
                default: "default",
            };
            break;
        case "subFlowNode":
            content = {
                subFlow: {
                    id: "",
                    name: "",
                    errorsInSubflow: 0,
                },
                workflowInputVariables: {},
                workflowOutputVariables: {},
                subflowInputVariables: [],
                subflowOutputVariables: [],
            };
            break;

        case "mediaListNode":
            content = {
                mediaVariable: "",
            };
            break;

        case "locationOutputNode":
            content = {
                isLocationFromVariable: false,
                location: {
                    latitude: 0,
                    longitude: 0,
                },
                variableName: "",
            };
            break;

        case "locationInputNode":
            content = {
                text: "",
            };
            break;

        case "dynamicButtonNode":
            content = {
                inputVariable: "",
                bodyText: "",
            };
            break;

        case "dynamicListNode":
            content = {
                openButtonText: "",
                bodyText: "",
                list: [],
            };
            break;

        case "delayNode":
            content = {
                delayIntervalInSeconds: 0,
                intervals: {
                    seconds: 0,
                    minutes: 0,
                    hours: 0,
                    days: 0,
                    months: 0,
                    years: 0,
                },
            };
            break;

        case "sendEmailCard":
            content = {
                emailBody: "",
                subject: "",
                attachments: [], // array of object { filename:'', path:'', isUploadedFile:true }
                receipientEmail: "",
                integrationId: "",
                senderEmail: "", // Default for now #TODO : Change It
                senderDisplayName: "", // Default for now #TODO : Change It
                replyToEmail: "", // Default for now #TODO : Change It
                replyToDisplayName: "", // Default for now #TODO : Change It
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "sendSMSCard":
            content = {
                integrationId: "",
                receipientNumber: "",
                message: null,
                messageVariableArray: [], // it will be { name :'variable name' , value:'variable value' }
                senderName: "", // Default for now #TODO : Change It
                dltentityId: "", // Default for now #TODO : Change It
                dlttempId: "", // Default for now #TODO : Change It
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
                noMoreCreditsHandleId: `${nodeId}_noMoreCredits`,
            };
            break;

        case "jsonMapperCard":
            content = {
                successHandleId: `${nodeId}_success`,
                onErrorHandleId: `${nodeId}_onError`,
                withSourceData: true,
                inputObjectArrayVariable: {
                    variableName: "",
                    variableId: "",
                    path: "",
                    pathVariableId: "",
                },
                propertyArray: [
                    // { propertyName: '', type: '', byVariable: false, from: '', value: '' }
                ],
            };
            break;

        case "dateSelectorCard":
            content = {
                from: "", // today, tomorrow
                to: "", // days , months,
                number: "", // 1-30
            };
            break;

        case "liveAgentNode":
            content = {
                messageAfterLiveAgentConnect: "",
                timeOutInSeconds: 0,
                isTimeOutByVariable: false,
                resolveHandleId: `${nodeId}_resolve`,
                notAvailableHandleId: `${nodeId}_notAvailable`,
                timeOutHandleId: `${nodeId}_timeOut`,
                failureHandleId: `${nodeId}_failure`,
                callbackRegistrationRegisterHandleId: `${nodeId}_registerCallback`,
                callbackRegistrationCancelHandleId: `${nodeId}_cancelCallbackRegistration`,
                liveAgentDisconnectKeywords: {},
                proceedHandleId: `${nodeId}_proceed`,
                liveAgentNotAvailableButtonContents: {
                    bodyText: "",
                    buttons: {
                        register: {
                            id: generateId(),
                            handleId: `${nodeId}_registerCallback`,
                            value: "",
                            type: "register",
                        },
                        cancelRegister: {
                            id: generateId(),
                            handleId: `${nodeId}_cancelCallbackRegistration`,
                            value: "",
                            type: "cancelRegister",
                        },
                    },
                },
                liveAgentWhenCallbackReceived: {
                    bodyText: "",
                    buttons: {
                        connectToLiveAgent: {
                            id: generateId(),
                            handleId: `${nodeId}_connectToLiveAgentHandle`,
                            value: "",
                            type: "connectToLiveAgent",
                        },
                        skipLiveAgentCallback: {
                            id: generateId(),
                            handleId: `${nodeId}_skipLiveAgentCallback`,
                            value: "",
                            type: "skipLiveAgentCallback",
                        },
                    },
                },
            };
            break;

        case "getRecipientDataNode":
            content = {
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        case "saveRecipientDataNode":
            content = {
                recipientInfo: {},
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "variableAssignmentNode":
            content = {
                variableAssign: [
                    // {id:'', targetVariable:'', sourceVariable:'' }
                ],
            };
            break;

        case "constantAssignmentCard":
            content = {
                variableAssignArray: [], // { id:'', variable:'', value:'' }
            };
            break;

        case "documentCognitionCard": {
            content = {
                selectedConfig: {},
            };
            break;
        }

        case "responseCard": {
            content = {
                isResponseStatusCodeByVariable: false,
                responseStatusCode: 200,
                isResponseContentTypeByVariable: false,
                responseContentType: "application/json",
                response: "",
            };
            break;
        }

        case "broadcastNode": {
            content = {
                whatsapp: {
                    isChecked: false,
                    integrationId: "",
                },
                sms: {
                    isChecked: false,
                    integrationId: "",
                },
                email: {
                    isChecked: false,
                    integrationId: "",
                },
                payloadVariable: "",
                successHandleId: `${nodeId}_success`,
                rateLimitingHandleId: `${nodeId}_rate_limiting`,
                // unauthorizedHandleId: `${nodeId}_unauthorized`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }
        case "messagingServiceNode": {
            content = {
                payloadVariable: "",
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
                createShortLink: false,
                whatsapp: {
                    isSelected: false,
                    integrationId: "",
                },

                email: {
                    isSelected: false,
                    integrationId: "",
                },
                sms: {
                    isSelected: true,
                    integrationId: "",
                    dltCheck: false,
                    enableWebhookConfig: false,
                    webhookConfig: {
                        webhookUrlConfig: {
                            type: "",
                            url: "",
                        },

                        headers: [],

                        authentication: {
                            authenticate: false,
                            type: "",
                            bearer: {
                                tokenType: "",
                                headerKeyNameForToken: "Authorization",
                                keywordToAddBeforeToken: "",
                                static: {
                                    token: "",
                                },
                                dynamic: {
                                    url: "",
                                    method: "",
                                    headers: [],
                                    bodyType: "json",
                                    formData: [],
                                    json: [],
                                    tokenFieldName: "",
                                    expiresInFieldName: "",
                                },
                            },
                            basic_auth: {
                                username: "",
                                password: "",
                            },
                            api_key: {
                                key: "",
                                value: "",
                            },
                        },
                    },
                },
            };
            break;
        }

        case "httpTriggerNode": {
            content = {
                text: "",
                httpTriggerHandleId: `${nodeId}_httpTrigger`,
                registerHttpTriggerNotFoundHandleId: `${nodeId}_registerHttpTriggerNotFound`,
                exceptionHandleId: `${nodeId}_httpTriggerException`, // Here The Exception could be network failed to check for register trigger, DB failed, timeout event scheduler failed, etc. with any of these reasons trigger could not enter the wait state to receive trigger request.
            };
            break;
        }

        case "registerHttpTriggerNode": {
            content = {
                httpTriggerNodeId: "",
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }

        case "rabbitMqNode":
            content = {
                queueName: "",
                payloadToQueue: {},
                payloadVariable: "",
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "broadcastSMSNode": {
            content = {
                integrationId: "",
                payloadVariable: "",
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }

        case "setLocaleNode": {
            content = {
                selectedLocale: "",
            };
            break;
        }

        case "whatsAppFlowsNode": {
            content = {
                headerText: "",
                bodyText: "",
                footerText: "",
                openButtonText: "",
                flowData: {
                    flowId: "",
                    flowName: "",
                    screenNameToNavigate: "",
                    dataChannelComponent: false,
                    firstScreenVariables: [],
                },
            };
            break;
        }

        case "whatsAppTemplateNode": {
            content = {
                templateCategory: "",
                templateDataByVariable: "",
                getItemsFromVariable: true,
                templateData: {
                    templateType: "",
                    templateName: "",
                    carousel: {
                        body: {},
                        cards: [],
                    },
                    templatePayload: "",
                    templateLanguageCode: "",
                },
                templateBodyPlaceHolder: "",
            };
            break;
        }

        case "whatsAppPaymentNode": {
            content = {
                headerText: "",
                bodyText: "",
                footerText: "",
                paymentConfigurationData: {
                    payment_currency: "",
                    payment_method: "",
                    payment_name: "",
                },
                discount: {
                    value: "",
                    description: "",
                },
                shipping: {
                    value: "",
                    description: "",
                },
                tax: {
                    value: "",
                },
                response: {
                    successResponse: "",
                    failureResponse: "",
                },
                itemsByVariable: "",
                items: [
                    {
                        itemName: "",
                        retailerId: "",
                        // productId: "",
                        itemAmount: "",
                        itemQuantity: "",
                        itemSaleAmount: "",
                    },
                ],
                expiration: {
                    timestamp: "",
                    description: "",
                },
                catalogId: "",
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }
        case "whatsAppCTANode": {
            content = {
                headerText: "",
                bodyText: "",
                footerText: "",
                text: "",
                url: "",
            };
            break;
        }

        case NODE_TYPES.CONNECTOR_NODE: {
            //TODO: get content from connector api
            content = {
                type: "",
                data: {},
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }

        case NODE_TYPES.WHATSAPP_CATALOG_NODE: {
            content = {
                headerText: "",
                bodyText: "",
                footerText: "",
                catalogId: "",
                sectionVariable: "",
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }
        case "rcsStandAloneNode": {
            content = {
                mediaType: "",
                mediaUrl: "",
                title: "",
                description: "",
                buttons: [],
                height: "MEDIUM",
                cardOrientation: "VERTICAL",
                thumbnailImageAlignment: 'LEFT',
                mediaFromType: "byUpload",
                isMediaRender: 'text'
            };
            break;
        }

        case 'cacheNode': {
            content = {
                operation: 'GET',
                cacheKey: "",
                value: '',
                ttl: 60,
                cacheLevel: 'PROCESS',
                cachedValueType: 'string',
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }

        default: {
            content = {};
            break;
        }
    }

    // Handle all renderable nodes for localization
    switch (nodeType) {
        case "plainMessageTextNode":
        case "questionNode": {
            const temp = {};
            locales.forEach((locale) => {
                temp[locale.code] = content;
            });

            content = temp;
            break;
        }
        case "buttonNode": {
            const headerTextTemp = {};
            const bodyTextTemp = {};
            const footerTextTemp = {};
            const buttonsTemp = [];
            locales.forEach((locale) => {
                headerTextTemp[locale.code] = "";
                bodyTextTemp[locale.code] = "";
                footerTextTemp[locale.code] = "";
            });
            content = {
                ...content,
                headerText: headerTextTemp,
                bodyText: bodyTextTemp,
                footerText: footerTextTemp,
                buttons: buttonsTemp,
            };
            break;
        }
        case "listNode": {
            const openButtonTextTemp = {};
            const bodyTextTemp = {};
            const list = [];
            locales.forEach((locale) => {
                openButtonTextTemp[locale.code] = "";
                bodyTextTemp[locale.code] = "";
            });
            content = {
                openButtonText: openButtonTextTemp,
                bodyText: bodyTextTemp,
                list: list,
            };
            break;
        }

        case "carouselNode": {
            const bodyTextTemp = {};
            const tabIndex = 0;
            const carousels = [];
            const buttons = [];

            locales.forEach((locale) => {
                bodyTextTemp[locale.code] = "";
            });
            switch (version) {
                case "2.0": {
                    break
                }

                default: {
                    content = {
                        bodyText: bodyTextTemp,
                        carousels,
                        buttons,
                        tabIndex,
                    };
                    break;
                }
            }

            break;
        }


        case "mediaNode": {
            const tempCaption = {};
            locales.forEach((locale) => {
                tempCaption[locale.code] = "";
            });
            content = {
                ...content,
                caption: tempCaption, //max 1024 char
            };
            break;
        }

        case "mediaInputNode": {
            const textTemp = {};
            locales.forEach((locale) => {
                textTemp[locale.code] = "";
            });
            content = {
                text: textTemp,
            };
            break;
        }

        case "locationInputNode": {
            const textTemp = {};
            locales.forEach((locale) => {
                textTemp[locale.code] = "";
            });
            content = {
                text: textTemp,
            };
            break;
        }
        case "dynamicButtonNode": {
            const bodyTextTemp = {};
            locales.forEach((locale) => {
                bodyTextTemp[locale.code] = "";
            });
            content = {
                inputVariable: "",
                bodyText: bodyTextTemp,
            };
            break;
        }
        case "dynamicListNode": {
            const openButtonTextTemp = {};
            const bodyTextTemp = {};
            locales.forEach((locale) => {
                bodyTextTemp[locale.code] = "";
                openButtonTextTemp[locale.code] = "";
            });
            content = {
                openButtonText: openButtonTextTemp,
                bodyText: bodyTextTemp,
                list: [],
            };
            break;
        }

        case "liveAgentNode": {
            const tempLocaleContent = {};
            locales.forEach((locale) => {
                tempLocaleContent[locale.code] = "";
            });
            const newContent = {
                ...content,
                messageAfterLiveAgentConnect: tempLocaleContent,
                liveAgentNotAvailableButtonContents: {
                    bodyText: tempLocaleContent,
                    buttons: {
                        register: {
                            id: generateId(),
                            handleId: `${nodeId}_registerCallback`,
                            value: tempLocaleContent,
                            type: "register",
                        },
                        cancelRegister: {
                            id: generateId(),
                            handleId: `${nodeId}_cancelCallbackRegistration`,
                            value: tempLocaleContent,
                            type: "cancelRegister",
                        },
                    },
                },
                liveAgentWhenCallbackReceived: {
                    bodyText: tempLocaleContent,
                    buttons: {
                        connectToLiveAgent: {
                            id: generateId(),
                            handleId: `${nodeId}_connectToLiveAgentHandle`,
                            value: tempLocaleContent,
                            type: "connectToLiveAgent",
                        },
                        skipLiveAgentCallback: {
                            id: generateId(),
                            handleId: `${nodeId}_skipLiveAgentCallback`,
                            value: tempLocaleContent,
                            type: "skipLiveAgentCallback",
                        },
                    },
                },
                liveAgentDisconnectKeywords: tempLocaleContent,
            };
            content = newContent;
            break;
        }

        case "documentCognitionCard": {
            const modelDisconnectKeywordsLocale = {};
            const initialMessageLocale = {};
            locales.forEach((locale) => {
                modelDisconnectKeywordsLocale[locale.code] = [];
                initialMessageLocale[locale.code] = "";
            });
            const newContent = {
                ...content,
                modelDisconnectKeywords: modelDisconnectKeywordsLocale,
                initialMessage: initialMessageLocale,
                selectedConfig: {},
            };
            content = newContent;
            break;
        }

        case "httpTriggerNode": {
            const textLocale = {};
            locales.forEach((locale) => {
                textLocale[locale.code] = "";
            });
            const newContent = {
                ...content,
                text: textLocale,
            };
            content = newContent;
            break;
        }

        case "whatsAppFlowsNode":
            const headerTextTemp = {};
            const bodyTextTemp = {};
            const footerTextTemp = {};
            const openButtonTextTemp = {};
            locales.forEach((locale) => {
                headerTextTemp[locale.code] = "";
                bodyTextTemp[locale.code] = "";
                footerTextTemp[locale.code] = "";
                openButtonTextTemp[locale.code] = "";
            });
            content = {
                ...content,
                headerText: headerTextTemp,
                bodyText: bodyTextTemp,
                footerText: footerTextTemp,
                openButtonText: openButtonTextTemp,
            };
            break;

        case "whatsAppTemplateNode":
            const templateCategory = {};
            const templateName = {};
            const templatePlaceholders = {};
            const headerTextTemp1 = {};
            const bodyTextTemp1 = {};
            const footerTextTemp1 = {};
            const openButtonTextTemp1 = {};

            locales.forEach((locale) => {
                templateCategory[locale.code] = "";
                templateName[locale.code] = "";
                headerTextTemp1[locale.code] = "";
                bodyTextTemp1[locale.code] = "";
                footerTextTemp1[locale.code] = "";
                openButtonTextTemp1[locale.code] = "";
            });

            content = {
                ...content,
                templateCategory: templateCategory,
                templateName: templateName,
                templatePlaceholders: templatePlaceholders,
                headerText: headerTextTemp1,
                bodyText: bodyTextTemp1,
                footerText: footerTextTemp1,
                openButtonText: openButtonTextTemp1,
            };
            break;

        case "whatsAppPaymentNode":
            const footerTextPem = {};
            const bodyTextPem1 = {};
            const responsePayment = { successResponse: {}, failureResponse: {} };
            locales.forEach((locale) => {
                footerTextPem[locale.code] = "";
                bodyTextPem1[locale.code] = "";
                responsePayment["successResponse"][locale.code] = "";
                responsePayment["failureResponse"][locale.code] = "";
            });
            content = {
                ...content,
                footerText: footerTextPem,
                bodyText: bodyTextPem1,
                response: {
                    successResponse: responsePayment.successResponse,
                    failureResponse: responsePayment.failureResponse,
                },
            };
            break;

        case 'whatsAppCatalogNode': {
            const headerTextTempCatalog = {};
            const bodyTextTempCatalog = {};
            const footerTextTempCatalog = {};

            locales.forEach((locale) => {
                headerTextTempCatalog[locale.code] = "";
                bodyTextTempCatalog[locale.code] = "";
                footerTextTempCatalog[locale.code] = "";
            });
            content = {
                ...content,
                headerText: headerTextTempCatalog,
                bodyText: bodyTextTempCatalog,
                footerText: footerTextTempCatalog,
            };
            break;
        }
        case "rcsStandAloneNode": {
            const title = {};
            const description = {};
            const rcsButton = [];

            locales.forEach((locale) => {
                title[locale.code] = "";
                description[locale.code] = "";
            });
            content = {
                ...content,
                title: title,
                description: description,
                buttons: rcsButton,
            };
            break;
        }
        // add data in locales
        case "whatsAppCTANode": {
            const headerTextTemp2 = {};
            const bodyTextTemp2 = {};
            const footerTextTemp2 = {};
            const textTemp = {};

            locales.forEach((locale) => {
                headerTextTemp2[locale.code] = "";
                bodyTextTemp2[locale.code] = "";
                footerTextTemp2[locale.code] = "";
                textTemp[locale.code] = "";
            });
            content = {
                ...content,
                headerText: headerTextTemp2,
                bodyText: bodyTextTemp2,
                footerText: footerTextTemp2,
                text: textTemp,
            };
            break;

        }

        case "sendSMSCard":
        case "sendEmailCard":
        case "optionNode":
        default:
            break;
    }
    // Handle all renderable nodes for localization

    return content;
};

export const getNodeTitle = (nodeType) => {
    const node = cardList.find((node) => node.type === nodeType);
    return node.title;
};

// =======================================================================================

export const getDuplicateNodeContent = (
    nodeType,
    nodeId,
    nodeContent,
    version = "1.0"
) => {
    let content = null;

    switch (nodeType) {
        case "plainMessageTextNode":
            content = nodeContent;
            break;
        case "questionNode":
            content = nodeContent;
            break;

        case "listNode":
            content = {
                openButtonText: nodeContent.openButtonText,
                bodyText: nodeContent.bodyText,
                list: nodeContent.list.map((item) => {
                    const id = generateId();
                    return {
                        value: item.value,
                        id: id,
                        handleId: `${nodeId}_${id}`,
                        description: item.description ?? "",
                    };
                }),
            };
            break;
        case "loopBackNode":
            content = {
                isSourceNodeConnected: false,
                isTargetNodeConnected: false,
                isValidCardTypePresentInLoopBackCycle: false,
            };
            break;

        case "optionNode":
            content = {
                header: nodeContent.header,
                options: [],
            };
            break;

        case "buttonNode":
            content = {
                fileDisplayNameForDocument: nodeContent.fileDisplayNameForDocument,
                isMediaRender: nodeContent.isMediaRender,
                isUrlByVariable: nodeContent.isUrlByVariable,
                mediaType: nodeContent.mediaType,
                mediaUrl: nodeContent.mediaUrl,
                headerText: nodeContent.headerText,
                bodyText: nodeContent.bodyText,
                footerText: nodeContent.footerText,
                mediaFromType: nodeContent.mediaFromType,
                buttons: nodeContent.buttons.map((button) => {
                    const id = generateId();
                    return { value: button.value, id: id, handleId: `${nodeId}_${id}` };
                }),
            };
            break;

        case "carouselNode":
            content = carouselNodeContents.getDuplicateContentByVersion(version, nodeId, nodeContent);
            break;

        case "mediaNode":
            content = {
                isDataFromUrl: nodeContent.isDataFromUrl,
                mediaType: nodeContent.mediaType,
                media: nodeContent.media,
                caption: nodeContent.caption, //max 1024 char
                fileDisplayNameForDocument: nodeContent.fileDisplayNameForDocument,
            };
            break;

        case "mediaInputNode":
            content = {
                text: nodeContent.text,
            };
            break;

        case "webhookNode":
            content = webhookNodeContents.getDuplicateContentByVersion(version, nodeId, nodeContent);
            break;

        case "conditionNode":
            content = {
                checkType: nodeContent.checkType,
                conditions: [
                    {
                        compareToValue: nodeContent.conditions[0].compareToValue,

                        conditionType: nodeContent.conditions[0].conditionType,

                        isCompareWithConstant:
                            nodeContent.conditions[0].isCompareWithConstant,
                        compareWithValue: nodeContent.conditions[0].compareWithValue,
                    },
                    {
                        compareToValue: nodeContent.conditions[1].compareToValue,

                        conditionType: nodeContent.conditions[1].conditionType,

                        isCompareWithConstant:
                            nodeContent.conditions[1].isCompareWithConstant,
                        compareWithValue: nodeContent.conditions[1].compareWithValue,
                    },
                ],
            };
            break;

        case "iterationGuardNode":
            content = {
                maxValue: nodeContent.maxValue,
                isMaxValueFromVariable: nodeContent.isMaxValueFromVariable,
                nextNodeOnContinue: `${nodeId}_continue`,
                nextNodeOnBreak: `${nodeId}_break`,
                isContinueHandleConnected: false,
                isBreakHandleConnected: false,
            };
            break;

        case "scriptNode":
            content = scriptNodeContents.getDuplicateContentByVersion(
                version,
                nodeId,
                nodeContent
            );
            break;

        case "channelSwitchNode":
            content = [];
            break;

        case "switchConditionNode":
            content = {
                ...nodeContent,
                conditions: nodeContent.conditions.map((condition) => {
                    const newConditionId = generateId();
                    return {
                        ...condition,
                        id: newConditionId,
                        handleId: `${nodeId}_${newConditionId}`,
                    };
                }),
            };
            break;
        case "subFlowNode":
            content = nodeContent;
            break;

        case "mediaListNode":
            content = nodeContent;
            break;

        case "locationOutputNode":
            content = nodeContent;
            break;

        case "locationInputNode":
            content = nodeContent;
            break;

        case "dynamicButtonNode":
            content = nodeContent;
            break;

        case "dynamicListNode":
            content = nodeContent;
            break;

        case "delayNode":
            content = nodeContent;
            break;

        case "sendEmailCard":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "sendSMSCard":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
                noMoreCreditsHandleId: `${nodeId}_noMoreCredits`,
            };
            break;

        case "jsonMapperCard":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                onErrorHandleId: `${nodeId}_onError`,
            };
            break;

        case "getRecipientDataNode":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        case "saveRecipientDataNode":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "dateSelectorCard":
            content = nodeContent;
            break;

        case "liveAgentNode":
            content = {
                ...nodeContent,
                resolveHandleId: `${nodeId}_resolve`,
                notAvailableHandleId: `${nodeId}_notAvailable`,
                timeOutHandleId: `${nodeId}_timeOut`,
                failureHandleId: `${nodeId}_failure`,
                callbackRegistrationRegisterHandleId: `${nodeId}_registerCallback`,
                callbackRegistrationCancelHandleId: `${nodeId}_cancelCallbackRegistration`,
                proceedHandleId: `${nodeId}_proceed`,
                liveAgentNotAvailableButtonContents: {
                    bodyText: nodeContent.liveAgentNotAvailableButtonContents.bodyText,
                    buttons: {
                        register: {
                            id: generateId(),
                            handleId: `${nodeId}_registerCallback`,
                            value:
                                nodeContent.liveAgentNotAvailableButtonContents.buttons.register
                                    .value,
                            type: "register",
                        },
                        cancelRegister: {
                            id: generateId(),
                            handleId: `${nodeId}_cancelCallbackRegistration`,
                            value:
                                nodeContent.liveAgentNotAvailableButtonContents.buttons
                                    .cancelRegister.value,
                            type: "cancelRegister",
                        },
                    },
                },
                liveAgentWhenCallbackReceived: {
                    bodyText: nodeContent.liveAgentWhenCallbackReceived.bodyText,
                    buttons: {
                        connectToLiveAgent: {
                            id: generateId(),
                            handleId: `${nodeId}_connectToLiveAgentHandle`,
                            value:
                                nodeContent.liveAgentWhenCallbackReceived.buttons
                                    .connectToLiveAgent.value,
                            type: "connectToLiveAgent",
                        },
                        skipLiveAgentCallback: {
                            id: generateId(),
                            handleId: `${nodeId}_skipLiveAgentCallback`,
                            value:
                                nodeContent.liveAgentWhenCallbackReceived.buttons
                                    .skipLiveAgentCallback.value,
                            type: "skipLiveAgentCallback",
                        },
                    },
                },
                messageAfterLiveAgentConnect: nodeContent.messageAfterLiveAgentConnect,
            };
            break;

        case "variableAssignmentNode":
            content = nodeContent;
            break;

        case "constantAssignmentCard":
            content = nodeContent;
            break;

        case "documentCognitionCard":
            content = nodeContent;
            break;

        case "responseCard":
            content = nodeContent;
            break;

        case "broadcastNode": {
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                rateLimitingHandleId: `${nodeId}_rate_limiting`,
                // unauthorizedHandleId: `${nodeId}_unauthorized`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }
        case "httpTriggerNode":
            content = {
                ...nodeContent,
                httpTriggerHandleId: `${nodeId}_httpTrigger`,
                registerHttpTriggerNotFoundHandleId: `${nodeId}_registerHttpTriggerNotFound`,
                exceptionHandleId: `${nodeId}_httpTriggerException`,
            };
            break;

        case "registerHttpTriggerNode": {
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }

        case "rabbitMqNode":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "broadcastSMSNode":
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;

        case "messagingServiceNode": {
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }

        case "setLocaleNode":
            content = {
                ...nodeContent,
            };
            break;

        case 'whatsAppFlowsNode':
            content = {
                headerText: nodeContent.headerText,
                bodyText: nodeContent.bodyText,
                footerText: nodeContent.footerText,
                openButtonText: nodeContent.openButtonText,
                firstScreenSubmitAction: nodeContent.firstScreenSubmitAction,
            };
            break;

        case "whatsAppTemplateNode":
            content = {
                ...nodeContent,
                // templateCategory: nodeContent.templateCategory,
                // templateName: nodeContent.templateName,
                // templatePlaceholders: nodeContent.templatePlaceholders,
                // headerText: nodeContent.headerText,
                // bodyText: nodeContent.bodyText,
                // footerText: nodeContent.footerText,
                // openButtonText: nodeContent.openButtonText,
            };
            break;

        case "whatsAppPaymentNode": {
            content = {
                // headerText: nodeContent.headerText,
                // bodyText: nodeContent.bodyText,
                // footerText: nodeContent.footerText,
                // paymentConfigurationData: nodeContent.paymentConfigurationData,
                // itemsByVariable: nodeContent.itemsByVariable,
                // items: nodeContent.items,
                // discount: nodeContent.discount,
                // shipping: nodeContent.shipping,
                // tax: nodeContent.tax,
                // response: nodeContent.response,
                // expiration: nodeContent.expiration,
                // catalogId: nodeContent.catalogId,
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }


        case NODE_TYPES.CONNECTOR_NODE:
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        case "whatsAppCatalogNode": {
            content = {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            };
            break;
        }
        case "rcsStandAloneNode": {
            content = {
                ...nodeContent,
                buttons: nodeContent.buttons.map((button) => {
                    const id = generateId();
                    return { text: button.text, id: id, handleId: `${nodeId}_${id}`, type: button.type, value: button.value };
                }),
            };
            break;
        }

        case 'whatsAppCTANode':
            content = {
                ...nodeContent
            };
            break;

        case 'cacheNode': {
            content = {
                ...content,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            }
            break;
        }
        default:
            content = {};
            break;
    }
    return content;
};

const scriptNodeContents = {
    versionContents: (nodeId) => {
        return {
            "1.0": { code: "" },
            "2.0": {
                code: "",
                onErrorHandleId: `${nodeId}_on_error`,
                onSuccessHandleId: `${nodeId}_on_success`,
            },
        };
    },

    versionContentsForDuplicateNode: (nodeId, nodeContent) => {
        return {
            "1.0": { code: nodeContent.code },
            "2.0": {
                code: nodeContent.code,
                onErrorHandleId: `${nodeId}_on_error`,
                onSuccessHandleId: `${nodeId}_on_success`,
            },
        };
    },

    /**
     * @param { string } version
     * @param { string } nodeId
     * @returns { object }
     */
    getContentByVersion: (version, nodeId) => {
        const versionContents = scriptNodeContents.versionContents(nodeId);

        if (versionContents.hasOwnProperty(version)) {
            return versionContents[version];
        }

        console.log("Invalid version number in script node...");
        return versionContents["1.0"];
    },

    /**
     * @param { string } version
     * @param { string } nodeId
     * @param { object } nodeContent
     * @returns { object }
     */
    getDuplicateContentByVersion: (version, nodeId, nodeContent) => {
        const versionContents = scriptNodeContents.versionContentsForDuplicateNode(
            nodeId,
            nodeContent
        );

        if (versionContents.hasOwnProperty(version)) {
            return versionContents[version];
        }

        console.log(
            "Invalid version number in script node while duplicating node..."
        );
        return versionContents["1.0"];
    },
};

const webhookNodeContents = {

    versionContents: (nodeId) => {
        return {
            "1.0": {
                method: "GET",
                statusCodeHandles: [{ code: '200', text: 'OK', handleId: `${nodeId}_200` },],
                unhandledHandelId: `${nodeId}_unhandled`, // default
                url: "",
                headers: [],
                body: null,
                bodyVariable: null, // selected variables value
                testWebhook: { // this will capture and test webhook data
                    output: " ", // store in stringify data
                    suggestedVariable: [],
                },
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            },
            "2.0": {
                method: "GET",
                statusCodeHandles: [{ code: '200', text: 'OK', handleId: `${nodeId}_200` },],
                unhandledHandelId: `${nodeId}_unhandled`, // default
                url: "",
                headers: [],
                contentType: "application/json",
                json: "",
                formData: [],
                urlEncoded: [],
                html: "",
                xml: "",
                isBodybyVariable: false, // post request body is from variable
                bodyVariable: null, // selected variables value
                testWebhook: { // this will capture and test webhook data
                    output: " ", // store in stringify data
                    suggestedVariable: [],
                },
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
            },
        }
    },

    versionContentsForDuplicateNode: (nodeId, nodeContent) => {
        return {
            "1.0": {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
                method: nodeContent.method,
                statusCodeHandles: [
                    { code: '200', text: 'OK', handleId: `${nodeId}_200` },
                ],
                unhandledHandelId: `${nodeId}_unhandled`,
                url: nodeContent.url,
                headers: nodeContent.headers,
                body: nodeContent.body,
                testWebhook: {
                    output: nodeContent.testWebhook.output,
                    suggestedVariable: nodeContent.testWebhook.suggestedVariable,
                },
            },
            "2.0": {
                ...nodeContent,
                successHandleId: `${nodeId}_success`,
                failureHandleId: `${nodeId}_failure`,
                method: nodeContent.method,
                statusCodeHandles: [
                    { code: '200', text: 'OK', handleId: `${nodeId}_200` },
                ],
                unhandledHandelId: `${nodeId}_unhandled`,
                url: nodeContent.url,
                headers: nodeContent.headers,
                contentType: nodeContent.contentType,
                json: nodeContent.json,
                formData: nodeContent.formData,
                urlEncoded: nodeContent.urlEncoded,
                html: nodeContent.html,
                xml: nodeContent.xml,
                testWebhook: {
                    output: nodeContent.testWebhook.output,
                    suggestedVariable: nodeContent.testWebhook.suggestedVariable,
                },
            }
        }
    },

    /**
     * @param { string } version
     * @param { string } nodeId
     * @returns { object }
     */
    getContentByVersion: (version, nodeId) => {
        const versionContents = webhookNodeContents.versionContents(nodeId);

        if (versionContents.hasOwnProperty(version)) {
            return versionContents[version];
        }

        console.log("Invalid version number in webhook node...")
        return versionContents["1.0"];
    },

    /**
     * @param { string } version
     * @param { string } nodeId
     * @param { object } nodeContent
     * @returns { object }
     */
    getDuplicateContentByVersion: (version, nodeId, nodeContent) => {
        const versionContents = webhookNodeContents.versionContentsForDuplicateNode(nodeId, nodeContent);

        if (versionContents.hasOwnProperty(version)) {
            return versionContents[version];
        }

        console.log(
            "Invalid version number in script node while duplicating node..."
        );
        return versionContents["1.0"];
    }

}


const carouselNodeContents = {

    versionContents: (nodeId) => {
        return {
            "1.0": {
                tabIndex: 0,
                carousels: [],
                buttons: [],
                bodyText: "",
            },
            "2.0": {
                tabIndex: 0,
                fbMessenger: {
                    getPayloadFromVariable: false,
                    carouselPayloadByVariable: "",
                    carousels: [],
                    buttons: [],
                },
                instagram: {
                    getPayloadFromVariable: false,
                    carouselPayloadByVariable: "",
                    carousels: [],
                    buttons: [],
                },
                rcs: {
                    getPayloadFromVariable: false,
                    carouselPayloadByVariable: "",
                    cardWidth: 'medium',
                    mediaHeight: 'medium',
                    carousels: [nodeContentForCarousel.rcs(), nodeContentForCarousel.rcs()],
                },
                tubulu: {
                    getPayloadFromVariable: false,
                    carouselPayloadByVariable: "",
                    carousels: [nodeContentForCarousel.tubulu(), nodeContentForCarousel.tubulu()],
                    suggestionButtons: [{ text: '', id: generateId() }],
                    bodyText: "",
                },
                whatsapp: {
                    actualPayload: "",
                    templateData: {
                        templateType: "",
                        templateName: "",
                        carousel: {
                            body: {},
                            cards: [],
                        },
                        templatePayload: "",
                        templateLanguageCode: "",
                        templateBodyPlaceHolder: "",//May be need to remove
                        templateBody: "", //may be need to remove
                    },
                },
            },
        }
    },

    versionContentsForDuplicateNode: (nodeId, nodeContent, version) => {
        switch (version) {
            case '2.0': {
                return {
                    ...nodeContent,
                    tabIndex: 0,
                    selectedChannel: nodeContent.selectedChannel,
                    fbMessenger: {
                        ...nodeContent.fbMessenger,
                        getPayloadFromVariable: nodeContent.fbMessenger.getPayloadFromVariable,
                        carouselPayloadByVariable: nodeContent.fbMessenger.carouselPayloadByVariable,
                        carousels: nodeContent.fbMessenger.carousels.map((carousel) => {
                            const id = generateId();
                            return {
                                ...carousel,
                                id: id,
                                imageUrl: carousel.imageUrl,
                                title: carousel.title,
                                buttons: carousel.buttons.map((button) => {
                                    const buttonId = generateId();
                                    return {
                                        ...button,
                                        id: buttonId,
                                        buttonName: button.buttonName,
                                        redirectUrl: button.redirectUrl,
                                    };
                                }),
                            };
                        }),
                        buttons: nodeContent.fbMessenger.buttons.map((button) => {
                            const buttonId = generateId();
                            return {
                                ...button,
                                id: buttonId
                            }
                        }),
                    },
                    instagram: {
                        ...nodeContent.instagram,
                        getPayloadFromVariable: nodeContent.instagram.getPayloadFromVariable,
                        carouselPayloadByVariable: nodeContent.instagram.carouselPayloadByVariable,
                        carousels: nodeContent.instagram.carousels.map((carousel) => {
                            const id = generateId();
                            return {
                                ...carousel,
                                id: id,
                                imageUrl: carousel.imageUrl,
                                title: carousel.title,
                                buttons: carousel.buttons.map((button) => {
                                    const buttonId = generateId();
                                    return {
                                        ...button,
                                        id: buttonId,
                                        buttonName: button.buttonName,
                                        redirectUrl: button.redirectUrl,
                                    };
                                }),
                            };
                        }),
                        buttons: nodeContent.instagram.buttons.map((button) => {
                            const buttonId = generateId();
                            return {
                                ...button,
                                id: buttonId
                            }
                        }),
                    },
                    rcs: {
                        ...nodeContent.rcs,
                        getPayloadFromVariable: nodeContent.rcs.getPayloadFromVariable,
                        carouselPayloadByVariable: nodeContent.rcs.carouselPayloadByVariable,
                        cardWidth: nodeContent.rcs.cardWidth,
                        mediaHeight: nodeContent.rcs.mediaHeight,
                        carousels: nodeContent.rcs.carousels.map((carousel) => {
                            const id = generateId();
                            return {
                                ...carousel,
                                id: id,
                                bodyText: carousel.bodyText,
                                imageUrl: carousel.imageUrl,
                                title: carousel.title,
                                buttons: carousel.buttons.map((button) => {
                                    const buttonId = generateId();
                                    return {
                                        ...button,
                                        id: buttonId,
                                        buttonName: button.buttonName,
                                        redirectUrl: button.redirectUrl,
                                    };
                                }),
                            };
                        })
                    },
                    tubulu: {
                        ...nodeContent.tubulu,
                        getPayloadFromVariable: nodeContent.tubulu.getPayloadFromVariable,
                        carouselPayloadByVariable: nodeContent.tubulu.carouselPayloadByVariable,
                        carousels: nodeContent.tubulu.carousels.map((carousel) => {
                            const id = generateId();
                            return {
                                ...carousel,
                                id: id,
                                imageUrl: carousel.imageUrl,
                                buttons: carousel.buttons.map((button) => {
                                    const buttonId = generateId();
                                    return {
                                        ...button,
                                        id: buttonId,
                                        buttonName: button.buttonName,
                                        redirectUrl: button.redirectUrl,
                                    };
                                }),
                            };
                        }),
                        suggestionButtons: nodeContent.tubulu.suggestionButtons.map((button) => {
                            const buttonId = generateId();
                            return {
                                ...button,
                                id: buttonId
                            }
                        }),
                        bodyText: nodeContent.tubulu.bodyText,
                    },
                    whatsapp: {
                        ...nodeContent.whatsapp
                    }

                }
            }

            default: {
                return {
                    ...nodeContent,
                    tabIndex: 0,
                    carousels: nodeContent.carousels.map((carousel) => {
                        const id = generateId();
                        return {
                            id: id,
                            bodyText: carousel.bodyText,
                            imageUrl: carousel.imageUrl,
                            title: carousel.title,
                            buttons: carousel.buttons.map((button) => {
                                const buttonId = generateId();
                                return {
                                    id: buttonId,
                                    buttonName: button.buttonName,
                                    redirectUrl: button.redirectUrl,
                                };
                            }),
                        };
                    }), // carousels:[{title:'',bodyText:'', url:''},...];
                    buttons: [],
                    bodyText: nodeContent.bodyText,
                }
            }
        }
    },

    /**
     * @param { string } version
     * @param { string } nodeId
     * @returns { object }
     */
    getContentByVersion: (version, nodeId) => {
        const versionContents = carouselNodeContents.versionContents(nodeId);

        if (versionContents.hasOwnProperty(version)) {
            return versionContents[version];
        }

        console.log("Invalid version number in webhook node...")
        return versionContents["1.0"];
    },

    /**
     * @param { string } version
     * @param { string } nodeId
     * @param { object } nodeContent
     * @returns { object }
     */
    getDuplicateContentByVersion: (version, nodeId, nodeContent) => {
        const versionContents = carouselNodeContents.versionContentsForDuplicateNode(nodeId, nodeContent, version);

        return versionContents;
    }

}


const nodeContentForCarousel = {
    rcs: () => {
        return {
            id: generateId(),
            title: '',
            bodyText: '',
            media: {
                fileUrl: '',
                mediaType: 'url',
                type: 'image'
            },
            buttons: [],
        };
    },
    tubulu: () => {
        return {
            id: generateId(),
            imageUrl: "",
            buttons: [],
            isExpanded: false,
            mediaFromType: "previousUpload",
        };
    }
}