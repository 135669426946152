import { getDuplicateNodeContent, getNodeTitle } from "../../pages/pages/Bots/workflow-builder/utils/generateContentForNode";
import { generateNodeValidation } from "../../pages/pages/Bots/workflow-builder/utils/generateNodeValidation";
import { NODE_TYPES } from "../../utils/Constants/WorkflowConstants";
import { generateId } from "./builderStoreLogic";
import { nodeValidationsLogic } from "./validations";

export const createNewNodeUsingExistingNode = ({
    positionOfNewNode,
    nodeToDuplicate,
    startNode,
    localeSupportedByBot
}) => {
    const { type, data, methods, height, width } = nodeToDuplicate;
    // before duplicating the mark the node property isStartNode as false;

    const id = generateId();
    const nodeValidations = generateNodeValidation(type);

    let connectorObject = {};

    if (type === NODE_TYPES.CONNECTOR_NODE) {
        connectorObject = {
            connectorType: data.connectorType ?? '',
            integrationType: data.integrationType ?? '',
            icon: data.icon ?? '',
            contents: data.contents ?? {},
        }
    }

    const newDataObject = {
        ...connectorObject,
        label: ``,
        belongsToChannel: [],
        parentNodesIdArray: [],
        contents: getDuplicateNodeContent(type, id, data.contents, data.version),
        // // if copied content paste in different Organization remove all content 
        // contents: contentMetadata.orgId === currentOrganizationData._id ?
        //     getDuplicateNodeContent(type, id, data.contents, data.version) :
        //     getNodeContent(type, id, locales, data.version),
        title: getNodeTitle(type),
        isStartNode: false,
        outputVariable: null,
        inputVariable: null,
        validateCalledAt: null,
        errors: [],
        reports: {
            errors: {},
            warnings: {}
        },
        validation: nodeValidations,
        version: data.version ?? "1.0",
    };

    // Checking for inputTimeout field
    // And assigning to new node
    if (data.inputTimeout) {
        newDataObject.inputTimeout = data.inputTimeout;
    }

    if (type === "channelSwitchNode") {
        //  generate handlerId for channel switch
        let channels = data.channels.map((channel) => {  // replace belongsToChannel with 'channels'
            return {
                channelId: channel.channelId,
                channelName: channel.channelName,
                handleId: `${id}_${channel.channelId}`,
                isActive: true,
            };
        });
        newDataObject["channels"] = channels;
    }
    const newNode = {
        id,
        type: type,
        position: positionOfNewNode,
        data: newDataObject,
        methods: methods,
        height,
        width
    };
    const reports = nodeValidationsLogic.validateNodeForChannel(
        newNode,
        localeSupportedByBot
    );
    newNode.data.reports = reports;
    return newNode;
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const filterNodesAndEdgesBeforePaste = ({ event,
    copiedNodesAndEdges,
    stateNodes,
    stateEdges,
    localeSupportedByBot,
    currentMouseCoordinates,
    contentMetadata,
    currentOrganizationData,
    locales,
}) => {
    let nodes = [];
    let edges = copiedNodesAndEdges.edges;

    if (copiedNodesAndEdges.nodes.length > 0) {
        // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        let position = currentMouseCoordinates;

        let firstNodePosition = null;

        copiedNodesAndEdges.nodes.forEach((node, index) => {
            let positionOfNewNode = {};
            if (index === 0) {
                positionOfNewNode = position;
                firstNodePosition = node.position;
            } else {
                const previousX = firstNodePosition.x - node.position.x;
                const previousY = firstNodePosition.y - node.position.y;
                positionOfNewNode = {
                    x: position.x - previousX,
                    y: position.y - previousY,
                };
            }

            // if nodes already exist make then duplicate
            if (stateNodes.find(stateNode => stateNode.id === node.id)) {
                let startNode = null;
                if (node.type === 'channelSwitchNode') {
                    startNode = node;
                }

                const oldNodeHandleIds = extractHandleIdsFromDataContentUsingNodeType(node);

                const newNode = createNewNodeUsingExistingNode({
                    nodeToDuplicate: node,
                    startNode,
                    localeSupportedByBot,
                    positionOfNewNode,
                    contentMetadata,
                    currentOrganizationData,
                    locales,
                });

                const newNodeHandleIds = extractHandleIdsFromDataContentUsingNodeType(newNode);

                for (let index = 0; index < newNodeHandleIds.length; index++) {
                    const oldHandleId = oldNodeHandleIds[index];
                    const newHandleId = newNodeHandleIds[index];
                    const edgesInString = JSON.stringify(edges);
                    const edgesWithReplacedNodeId = edgesInString.replaceAll(oldHandleId, newHandleId);
                    edges = JSON.parse(edgesWithReplacedNodeId);
                }

                const edgesInString = JSON.stringify(edges);
                const edgesWithReplacedNodeId = edgesInString.replaceAll(node.id, newNode.id);
                edges = JSON.parse(edgesWithReplacedNodeId);
                nodes.push(newNode);
            } else {
                const nodeWithUpdatedPosition = {
                    ...node,
                    position: positionOfNewNode,
                }
                nodes.push(nodeWithUpdatedPosition);
            }
        });
    }
    const newEdges = [];
    if (edges.length > 0) {
        edges.forEach(edge => {
            if (stateEdges.find(stateEdge => stateEdge.id === edge.id)) {
                const newEdge = { ...edge, id: generateId() };
                newEdges.push(newEdge);
            } else {
                newEdges.push(edge);
            }
        });
    }

    return { nodes, edges: newEdges };
};

export const extractHandleIdsFromDataContentUsingNodeType = (node) => {
    let handleId = [];
    switch (node.type) {
        case 'buttonNode': {
            handleId = node.data.contents.buttons.map(item => item.handleId);
            break;
        }
        case 'rcsStandAloneNode': {
            handleId = node.data.contents.buttons.map(item => item.handleId);
            break;
        }
        case 'listNode': {
            handleId = node.data.contents.list.map(item => item.handleId);
            break;
        }
        case 'switchConditionNode': {
            handleId = node.data.contents.conditions.map(item => item.handleId);
            break;
        }
        default:
            break;
    }
    return handleId;
} 