import { checkIfCardInInputCard } from "../../../../../redux/builderLogic/builderStoreLogic";
import { traversalForDeletingLoopBack, traverseToAllNodesForDeleteChannelIdAndParentId, validateAllNodes } from "../../../../../redux/builderLogic/traversal";
import { nodeValidationsLogic } from "../../../../../redux/builderLogic/validations";

/**
 * @typedef TSelectedNodesAndEdges
 * @property {object} nodes
 * @property {object} edges
 */

/**
 * Bulk Delete Nodes & Edges 
 * 
 * @param {object} stateStore 
 * @param {TSelectedNodesAndEdges} selectedNodesAndEdges 
 * @returns 
 */
export function deleteSelectedNodesAndEdges(stateStore, selectedNodesAndEdges) {
    if (!selectedNodesAndEdges) return

    try {

        const selectedNodes = selectedNodesAndEdges.nodes;
        let _nodes = structuredClone(stateStore.nodes);
        let _edges = structuredClone(stateStore.edges);

        // Handle all incoming edges of deleted node
        // This will disconnect all the incoming edges and mark their respective state as false

        // Store Ids of Node that we want to delete
        let deleteNodeIds = new Set([]);
        for (let index = 0; index < selectedNodes.length; index++) {
            const element = selectedNodes[index];
            // Skip Start Node 
            if (element.type === 'startNode') {
                continue;
            }

            deleteNodeIds.add(element.id);
        };

        const allInComingEdgesOfDeletedNode = _edges.filter(
            (edge) => deleteNodeIds.has(edge.target)
        );

        // Store node ids as key and its data as values to acces node data by its ids in O(1) time
        let objectOfAllNodeIds = {};   // { nodeId: nodeData }
        for (let index = 0; index < _nodes.length; index++) {
            const node = _nodes[index];
            objectOfAllNodeIds[node.id] = structuredClone(node);
        }

        for (let index = 0; index < allInComingEdgesOfDeletedNode.length; index++) {
            const edge = allInComingEdgesOfDeletedNode[index];
            // let sourceNodeOfIndividualEdge = findNode(_nodes, edge.source);
            let sourceNodeOfIndividualEdge = objectOfAllNodeIds[edge.source];

            let reports = sourceNodeOfIndividualEdge.data.reports ?? {
                errors: { messages: [] },
                warnings: { messages: [] },
            };
            if (
                edge.sourceHandle !== null &&
                edge.sourceHandle.includes("exception")
            ) {
                if (checkIfCardInInputCard(sourceNodeOfIndividualEdge.type) === true) {
                    // only validate if the validate property is set to true
                    if (
                        sourceNodeOfIndividualEdge.data.validation &&
                        sourceNodeOfIndividualEdge.data.validation.validate &&
                        sourceNodeOfIndividualEdge.data.validation.validate === true
                    ) {
                        sourceNodeOfIndividualEdge.data.validation = {
                            ...sourceNodeOfIndividualEdge.data.validation,
                            isOnErrorHandleConnected: false,
                        };
                    }
                }
            }

            // ====================== HANDLE INPUT TIMEOUT ======================
            if (
                edge.sourceHandle !== null &&
                edge.sourceHandle.includes("input_timeout")
            ) {
                if (checkIfCardInInputCard(sourceNodeOfIndividualEdge.type)) {
                    if (
                        sourceNodeOfIndividualEdge.data.inputTimeout &&
                        sourceNodeOfIndividualEdge.data.inputTimeout.enabled &&
                        sourceNodeOfIndividualEdge.data.inputTimeout.enabled === true
                    ) {
                        sourceNodeOfIndividualEdge.data.inputTimeout = {
                            ...sourceNodeOfIndividualEdge.data.inputTimeout,
                            isTimeoutHandleConnected: false,
                        };
                    }
                }
            }
            // ====================== HANDLE INPUT TIMEOUT ======================

            // if start node is disconnected
            if (sourceNodeOfIndividualEdge.type === "startNode") {
                sourceNodeOfIndividualEdge.data.contents = {
                    ...sourceNodeOfIndividualEdge.data.contents,
                    startNodeConnected: false,
                };
            }

            // ====================================== handles iteration guard condition ======================================
            if (
                sourceNodeOfIndividualEdge.type === "iterationGuardNode" &&
                edge.sourceHandle !== null &&
                (edge.sourceHandle.includes("continue") ||
                    edge.sourceHandle.includes("break"))
            ) {
                let handle = edge.sourceHandle.includes("continue")
                    ? "isContinueHandleConnected"
                    : "isBreakHandleConnected";

                sourceNodeOfIndividualEdge.data.contents = {
                    ...sourceNodeOfIndividualEdge.data.contents,
                    [`${handle}`]: false,
                };
            }

            // ====================================== handles iteration guard condition ======================================

            reports = nodeValidationsLogic.validateNodeForChannel(
                sourceNodeOfIndividualEdge,
                stateStore.localeSupportedByBot
            );

            sourceNodeOfIndividualEdge.data.reports = reports;
        } // end of for loop (incoming edge iteration) 

        // Deleting the outgoing edges of individual deleted node
        const allOutGoingEdgesOfDeletingNode = _edges.filter(
            (edge) => deleteNodeIds.has(edge.source)
        );

        for (let edge of allOutGoingEdgesOfDeletingNode) {
            // ----------------------  Only for LoopBackNode to delete target egde of loop back and update the content
            // let targetNodeOfEdge = newNodes.find((node) => node.id === edge.target);
            let targetNodeOfEdge = objectOfAllNodeIds[edge.target];
            // We are doing this to delete the loop back node handle
            const { nodes, edges } = traversalForDeletingLoopBack({
                nodes: _nodes,
                edges: _edges,
                // nodes: newNodes,
                // edges: newEdges,
                nodeId: edge.target,
                parentListOfSourceNode: targetNodeOfEdge.data.parentNodesIdArray,
            });
            _edges = structuredClone(edges);
            _nodes = nodes;
            if (targetNodeOfEdge.type === "loopBackNode") {
                targetNodeOfEdge = {
                    ...targetNodeOfEdge,
                    data: {
                        ...targetNodeOfEdge.data,
                        contents: {
                            ...targetNodeOfEdge.data.contents,
                            isSourceNodeConnected: false,
                        },
                    },
                };
            }
            // -------------------------------------------------------

            _nodes = traverseToAllNodesForDeleteChannelIdAndParentId({
                edges: _edges,
                nodes: _nodes,
                sourceNodeId: edge.source,
                targetNodeId: edge.target,
                sourceNodeHandle: edge.sourceHandle,
                connectedEdge: edge,
            });
        }


        // newEdges = newEdges.filter((edge) => edge.source !== action.nodeId);
        // newEdges = newEdges.filter((edge) => edge.target !== action.nodeId);
        _edges = _edges.filter((edge) => !deleteNodeIds.has(edge.source));
        _edges = _edges.filter((edge) => !deleteNodeIds.has(edge.target));

        //  remove the deleted the node from the node array
        // let newNodesArray = newNodes.filter((node) => node.id !== action.nodeId);
        let newNodesArray = _nodes.filter((node) => !deleteNodeIds.has(node.id));

        // remove all the variable and suggestion list which was generate throught this node
        // let newVariablesArray = stateStore.variables.filter(
        //     (element) => deleteNodeIds.has(element.nodeId)
        // );

        // let newSuggetionVariablesArray = stateStore.variableSuggestionList.filter(
        //     (element) => deleteNodeIds.has(element.nodeId)
        // );

        //  Validate all the error nodes
        const {
            nodes: updatedNodes,
            warningCount,
            errorCount,
        } = validateAllNodes({
            edges: _edges,
            stateVariables: stateStore.variables,
            stateVariableSuggestionList: stateStore.variableSuggestionList,
            nodes: newNodesArray,
            // nodeId: [],  // for our case this is an array
            localeSupportedByBot: stateStore.localeSupportedByBot,
        });

        const newState = {
            ...stateStore,
            nodes: updatedNodes,
            edges: _edges,
            currentlySelectedNode: null,
            latestSelectedNodeOrEdge: null,
            currentlySelectedEdge: null,
            variables: stateStore.variables,
            variableSuggestionList: stateStore.variableSuggestionList,
            logCount: {
                errorCount: errorCount,
                warningCount: warningCount,
            },
        };

        return newState;

    } catch (error) {
        console.log('deleteSelectedNodesAndEdges', error);
        return stateStore;
    }
}